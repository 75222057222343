import React from 'react';
import { Box, Heading, Text, Button, VStack, Stack, HStack, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import settingsOptions from './settingOptions';

const SettingsPage = () => {
    const navigate = useNavigate();

    return (
        <Box p={6}>
            <Heading size="lg" mb={6}>Settings</Heading>
            <VStack spacing={4} align="stretch"> {/* Reduced spacing for compact view */}
                {settingsOptions.map((option) => (
                    <HStack
                        key={option.id}
                        bg="gray.50"
                        p={3} // Reduced padding for compact design
                        borderRadius="md"
                        boxShadow="sm"
                        justify="space-between"
                        align="center"
                    >
                        <HStack>
                            <Icon as={option.icon} boxSize={5} color="teal.500" /> {/* Smaller icon */}
                            <Box>
                                <Heading size="sm">{option.title}</Heading> {/* Smaller heading */}
                                <Text fontSize="xs" color="gray.600">{option.description}</Text> {/* Smaller description */}
                            </Box>
                        </HStack>
                        <Button size="sm" colorScheme="teal" onClick={() => navigate(option.route)}>
                            Manage
                        </Button>
                    </HStack>
                ))}
            </VStack>
        </Box>
    );
};

export default SettingsPage;