import { Box, Flex, Icon, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { colorMap } from '../../../utils/colors';


const MotionBox = motion(Box);

const FeatureCard = ({ title, color, icon, content }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
  const iconBgColor = useColorModeValue(`${color}.100`, `${color}.900`);
  const iconColor = useColorModeValue(`${color}.600`, `${color}.200`);
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <MotionBox
      p={6}
      h={245}
      bg={bgColor}
      rounded="xl"
      borderWidth="1px"
      borderColor={useColorModeValue('gray.100', 'gray.700')}
      shadow="sm"
      whileHover={{ 
        y: -5,
        shadow: '2xl',
        borderColor: colorMap[color][200]
      }}
      transition={{ duration: 0.3 }}
    >
      <VStack align="flex-start" spacing={4}>
        <Flex
          alignItems="center"
          justifyContent="center"
          w={12}
          h={12}
          rounded="full"
          bg={iconBgColor}
        >
          <Icon
            as={icon}
            boxSize={6}
            color={iconColor}
          />
        </Flex>
        
        <Text
          fontSize="xl"
          fontWeight="bold"
          bgGradient={`linear(to-r, ${colorMap[color][600]}, ${colorMap[color][400]})`}
          bgClip="text"
        >
          {title}
        </Text>
        
        <Text
          color={textColor}
          fontSize="md"
          lineHeight="tall"
        >
          {content}
        </Text>
      </VStack>
    </MotionBox>
  );
};

export default FeatureCard;