import React, { useState } from 'react';
import {
  Box,
  Heading,
  Textarea,
  VStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import BackToSettings from '../BackToSettings';

const StorePolicies = () => {
  const [policies, setPolicies] = useState({
    returnPolicy: 'We accept returns within 15 days of purchase, provided the item is unused and in its original condition.',
    refundPolicy: 'Refunds are processed within 7 business days after the return is approved.',
    shippingPolicy: 'Orders are processed and shipped within 2-3 business days.',
  });
  const toast = useToast();

  const handleSave = () => {
    toast({
      title: 'Store Policies Saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('Store Policies:', policies);
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={6}>Store Policies</Heading>
      <VStack spacing={4} align="stretch">
        <Textarea
          placeholder="Return Policy"
          value={policies.returnPolicy}
          onChange={(e) =>
            setPolicies({ ...policies, returnPolicy: e.target.value })
          }
          size="sm"
        />
        <Textarea
          placeholder="Refund Policy"
          value={policies.refundPolicy}
          onChange={(e) =>
            setPolicies({ ...policies, refundPolicy: e.target.value })
          }
          size="sm"
        />
        <Textarea
          placeholder="Shipping Policy"
          value={policies.shippingPolicy}
          onChange={(e) =>
            setPolicies({ ...policies, shippingPolicy: e.target.value })
          }
          size="sm"
        />
        <Button colorScheme="teal" onClick={handleSave}>
          Save Policies
        </Button>
      </VStack>
    </Box>
  );
};

export default StorePolicies;