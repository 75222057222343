import { useState, useMemo } from 'react';
import { plansList } from './plansList';

export const usePricing = () => {
  const [isYearly, setIsYearly] = useState(false);

  const plans = useMemo(() => {
    return plansList.map(plan => ({
      ...plan,
      price: plan.comingSoon ? 'XXXX' : calculatePrice(plan.price, isYearly)
    }));
  }, [isYearly]);

  return { plans, isYearly, setIsYearly };
};

const calculatePrice = (monthlyPrice, isYearly) => {
  if (monthlyPrice === 'XXXX') return 'XXXX';
  const monthly = parseInt(monthlyPrice, 10);
  const yearly = monthly * 12 * 0.85; // 15% discount
  return isYearly ? Math.round(yearly).toString() : monthly.toString();
};