import React, { useRef, lazy, Suspense, useState, useEffect } from 'react';
import { Box, Button, VStack, Heading, HStack, Text, Flex, Spinner, Input } from '@chakra-ui/react';

import { Plus, Upload } from 'lucide-react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import ImportProductsModal from './ImportProductModal';
import CreateSampleProductModal from './CreateSampleProductModal';
import PrimaryActionButton from '../../../components/ActionButtons/PrimaryActionButton';
import { getPageContent, MODES } from './productHelpers';
import { FormProvider } from "../../../context/FormContext";

import APP_ROUTES from '../../../constant/appPath';

const ProductTable = lazy(() => import('./ProductTable'));
const AddProductPage = lazy(() => import('./Product/AddProductPage'));

const ProductPage = () => {
    const [editingProductId, setEditingProductId] = useState(null);
    const [activeModal, setActiveModal] = useState(null);
    const [isImporting, setIsImporting] = useState(false);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    // Get the mode from the URL query params or set it to "default"
    const mode = searchParams.get("mode") || "default";
    const productId = searchParams.get("productId");
    const { heading, description } = getPageContent(mode);

    // Open import modal by default if mode is "import"
    useEffect(() => {
        if (mode === MODES.SAMPLE_PRODUCT) setActiveModal('sample');
        if (mode === MODES.IMPORT) setActiveModal('import');
    }, [mode]);

    const closeModal = () => {
        setActiveModal(null);
        navigate('/products');
    };

    const handleFileImport = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            setIsImporting(true);
            setTimeout(() => {
                setIsImporting(false);
            }, 1500);
        }
    };

    const handleViewProducts = () => {
        navigate(APP_ROUTES.VIEW_PRODUCTS);
    };

    const handleAddProduct = () => {
        setEditingProductId(null);
        navigate(APP_ROUTES.ADD_PRODUCT);
    };

    const handleEditProduct = (productId) => {
        setEditingProductId(productId);
        navigate(`${APP_ROUTES.EDIT_PRODUCT}&productId=${productId}`);
    };

    const renderPageDesc = () => {
        return (
            <Box>
                <Heading as="h1" size="lg"> {heading} </Heading>
                <Text mt={1} color="gray.500"> {description}</Text>
            </Box>
        )
    };

    const defaultModeActionBtns = () => {
        return (
            <HStack spacing={4}>
                <HStack spacing={2}>
                    <Input
                        type="file"
                        accept=".csv,.xlsx"
                        display="none"
                        ref={fileInputRef}
                        onChange={handleFileImport}
                    />
                    <Button
                        variant="outline"
                        onClick={() => fileInputRef.current?.click()}
                        isDisabled={isImporting}
                        size="sm"
                        leftIcon={<Upload size="16px" />}
                    >
                        {isImporting ? 'Importing...' : 'Import Products'}
                    </Button>
                    <PrimaryActionButton size="sm" onClickAction={handleAddProduct}>Create Product</PrimaryActionButton>
                </HStack>

            </HStack>
        );
    };

    const otherModeActionBtns = () => {
        return (
            <HStack spacing={4}>
                <Button onClick={handleViewProducts} size="sm" variant="outline"> Back to Products </Button>
                <PrimaryActionButton size="sm" colorScheme="teal"> {mode === MODES.ADD ? 'Add Product' : 'Update Product'} </PrimaryActionButton>
            </HStack>
        );
    };

    const renderTopSection = () => {
        return (
            <Flex justify="space-between" align="center" mb={4}>
                {renderPageDesc()}
                {/* Render Create and import buttons on listing page */}
                {mode !== MODES.ADD && mode !== MODES.EDIT && defaultModeActionBtns()}
                {/* Render Back and Update buttons on Add/Edit page */}
                {(mode === MODES.ADD || mode === MODES.EDIT) && otherModeActionBtns()}
            </Flex>
        );
    };

    return (
        <>
            <VStack align="stretch" spacing={4} maxW="100%" w={{ base: "100%", md: "100%" }}>
                {renderTopSection()}
                <Suspense fallback={<Flex justify="center" align="center" h="200px"><Spinner size="lg" /></Flex>}>
                    {mode === MODES.ADD && <FormProvider><AddProductPage onClose={() => navigate(APP_ROUTES.VIEW_PRODUCTS)} /></FormProvider>}
                    {mode === MODES.EDIT && productId && (
                        <FormProvider><AddProductPage mode="edit" productId={productId} onClose={() => navigate(APP_ROUTES.VIEW_PRODUCTS)} /></FormProvider>
                    )}
                    {(mode !== MODES.ADD && mode !== MODES.EDIT) && <ProductTable onEditProduct={handleEditProduct} />}
                </Suspense>

                <ImportProductsModal isOpen={activeModal === 'import'} onClose={closeModal} />
                <CreateSampleProductModal isOpen={activeModal === 'sample'} onClose={closeModal} />

            </VStack>
        </>

    );
};

export default ProductPage;