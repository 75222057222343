import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';
import BackToSettings from '../BackToSettings';

const SupportDetails = () => {
  const [details, setDetails] = useState({
    email: '',
    phone: '',
    facebook: '',
    instagram: '',
    twitter: '',
  });
  const toast = useToast();

  const handleSave = () => {
    toast({
      title: 'Support Details Saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('Support Details:', details);
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={6}>
        Support Details
      </Heading>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            placeholder="Support email"
            value={details.email}
            onChange={(e) => setDetails({ ...details, email: e.target.value })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Phone</FormLabel>
          <Input
            placeholder="Support phone number"
            value={details.phone}
            onChange={(e) => setDetails({ ...details, phone: e.target.value })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Facebook URL</FormLabel>
          <Input
            placeholder="Facebook profile URL"
            value={details.facebook}
            onChange={(e) => setDetails({ ...details, facebook: e.target.value })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Instagram URL</FormLabel>
          <Input
            placeholder="Instagram profile URL"
            value={details.instagram}
            onChange={(e) => setDetails({ ...details, instagram: e.target.value })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Twitter URL</FormLabel>
          <Input
            placeholder="Twitter profile URL"
            value={details.twitter}
            onChange={(e) => setDetails({ ...details, twitter: e.target.value })}
          />
        </FormControl>
        <Button colorScheme="teal" onClick={handleSave}>
          Save Details
        </Button>
      </VStack>
    </Box>
  );
};

export default SupportDetails;