import { Box, Container, Heading, Text, VStack, SimpleGrid, Image, useColorModeValue, Icon, Flex, Circle } from "@chakra-ui/react";
import { FiHeart, FiTrendingUp, FiShield, FiUsers } from "react-icons/fi";
import { motion } from "framer-motion";

const MotionBox = motion(Box);



const values = [
  {
    icon: FiHeart,
    title: "Customer First",
    description: "We put our customers' success at the heart of everything we do."
  },
  {
    icon: FiTrendingUp,
    title: "Innovation",
    description: "We constantly innovate to provide cutting-edge solutions."
  },
  {
    icon: FiShield,
    title: "Trust & Security",
    description: "We maintain the highest standards of security and reliability."
  },
  {
    icon: FiUsers,
    title: "Community",
    description: "We build and nurture a strong community of entrepreneurs."
  }
];

const milestones = [
  {
    year: "2021",
    title: "Company Founded",
    description: "MiniTaka was founded with a vision to democratize e-commerce for small businesses."
  },
  {
    year: "2022",
    title: "First 1000 Stores",
    description: "Reached our first milestone of empowering 1000 small businesses with online stores."
  },
  {
    year: "2023",
    title: "Pan-India Expansion",
    description: "Expanded operations across all major cities in India, serving businesses nationwide."
  },
  {
    year: "2024",
    title: "Mobile App Launch",
    description: "Launched our mobile app to help merchants manage their stores on the go."
  }
];

const teamMembers = [
  {
    name: "Rahul Sharma",
    role: "CEO & Founder",
    image: "/assets/team/rahul.jpg",
    bio: "Former Product Lead at Flipkart with 15+ years of e-commerce experience."
  },
  {
    name: "Priya Patel",
    role: "CTO",
    image: "/assets/team/priya.jpg",
    bio: "Tech veteran with experience at Google and Amazon, passionate about scalable systems."
  },
  {
    name: "Amit Kumar",
    role: "Head of Customer Success",
    image: "/assets/team/amit.jpg",
    bio: "Customer experience expert focused on helping businesses grow online."
  }
];

const Values = () => (
  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
    {values.map((value) => (
      <Box key={value.title} p={6} bg="white" rounded="lg" shadow="md">
        <VStack align="start" spacing={4}>
          <Icon as={value.icon} boxSize={8} color="teal.500" />
          <Text fontSize="xl" fontWeight="bold">
            {value.title}
          </Text>
          <Text color="gray.600">
            {value.description}
          </Text>
        </VStack>
      </Box>
    ))}
  </SimpleGrid>
);

const Timeline = () => {
  const lineColor = useColorModeValue("gray.200", "gray.700");
  
  return (
    <VStack spacing={8} position="relative" align="stretch">
      <Box
        position="absolute"
        left="24px"
        top="0"
        bottom="0"
        width="2px"
        bg={lineColor}
      />
      
      {milestones.map((milestone, index) => (
        <MotionBox
          key={milestone.year}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: index * 0.2 }}
        >
          <Flex gap={6}>
            <Circle size="12" bg="teal.500" color="white">
              {milestone.year}
            </Circle>
            <Box pt={1}>
              <Text fontSize="xl" fontWeight="bold" mb={2}>
                {milestone.title}
              </Text>
              <Text color="gray.600">
                {milestone.description}
              </Text>
            </Box>
          </Flex>
        </MotionBox>
      ))}
    </VStack>
  );
};

const TeamMember = ({ name, role, image, bio }) => (
  <Box bg="white" p={6} rounded="lg" shadow="md">
    <VStack spacing={4}>
      <Image
        src={image}
        alt={name}
        borderRadius="full"
        boxSize="150px"
        objectFit="cover"
      />
      <Text fontWeight="bold" fontSize="lg">{name}</Text>
      <Text color="teal.500" fontSize="md">{role}</Text>
      <Text color="gray.600" textAlign="center">{bio}</Text>
    </VStack>
  </Box>
);

const About = () => (
  <Box py={16}>
    <Container maxW="7xl">
      <VStack spacing={16} align="stretch">
        {/* Hero Section */}
        <VStack spacing={6} textAlign="center">
          <Heading 
            size="2xl" 
            bgGradient="linear(to-r, teal.500, blue.500)" 
            bgClip="text"
          >
            Empowering Small Businesses
          </Heading>
          <Text fontSize="xl" color="gray.600" maxW="3xl" mx="auto">
            MiniTaka is on a mission to democratize e-commerce for small businesses 
            across India. We provide the tools and technology needed to succeed in 
            the digital economy.
          </Text>
        </VStack>

        {/* Stats Section */}
        {/* <Stats /> */}

        {/* Mission & Vision */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Box>
            <Heading size="lg" mb={4}>Our Mission</Heading>
            <Text color="gray.600" fontSize="lg">
              To empower small businesses with enterprise-grade e-commerce capabilities,
              making it easy for them to establish, manage, and grow their online presence.
              We believe every business deserves the opportunity to thrive in the digital age.
            </Text>
          </Box>
          <Box>
            <Heading size="lg" mb={4}>Our Vision</Heading>
            <Text color="gray.600" fontSize="lg">
              To become the most trusted partner for small businesses in their digital
              journey, helping millions of entrepreneurs across India realize their dreams
              of building successful online businesses.
            </Text>
          </Box>
        </SimpleGrid>

        {/* Values Section */}
        <Box>
          <Heading size="xl" mb={8} textAlign="center">Our Values</Heading>
          <Values />
        </Box>

        {/* Journey Timeline */}
        {/* <Box>
          <Heading size="xl" mb={8} textAlign="center">Our Journey</Heading>
          <Timeline />
        </Box> */}

        {/* Team Section */}
        {/* <Box>
          <Heading size="xl" mb={8} textAlign="center">Meet Our Team</Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
            {teamMembers.map((member) => (
              <TeamMember key={member.name} {...member} />
            ))}
          </SimpleGrid>
        </Box> */}
      </VStack>
    </Container>
  </Box>
);

export default About;