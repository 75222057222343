import React, { useState } from 'react';
import { Box, Input, Button, Text, HStack, Spinner, Link } from '@chakra-ui/react';
import {UPDATE_STORE_NAME} from '../services/apiConfig';
import apiHandler from '../services/apiHandler';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const ShopNameStep = ({storeDetails = ""}) => {
  const [shopName, setShopName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleUpdateShopName = async () => {
    setIsLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    try {
      // Simulate a POST request to update shop name
      const response = await apiHandler({options : UPDATE_STORE_NAME, data : {shopName}});
      
      // Assuming the response contains the updated name and URL of the shop
      const { name, url } = response.data;
      setShopName(name);

      // Display a success message with a link to the shop
      setSuccessMessage(
        <>
          Shop name is successfully updated.{' '}
          <Link href={url} isExternal>
            Open in new tab <ExternalLinkIcon mx="2px" />
          </Link>
        </>
      );
    } catch (error) {
      // Display an error message if the update fails
      setErrorMessage(error.response?.data?.message || 'Failed to update shop name. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
       {/* Display default store details */}
       {storeDetails?.domain_path && (
        <Text fontSize="sm" mb={3} color="gray.600">
          Your online store:{' '}
          <Link href={storeDetails.domain_path} color="blue.500" isExternal>
            {storeDetails.domain_path} <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>
      )}
      <HStack spacing={3} mb={2}>
        <Input
          placeholder="Enter shop name"
          value={shopName}
          onChange={(e) => setShopName(e.target.value)}
          size="sm" // Smaller input box size
          isDisabled={isLoading}
          width="200px"
        />
        <Button      
          colorScheme="blue"
          onClick={handleUpdateShopName}
          isDisabled={isLoading || !shopName}
          size="sm" // Smaller button size
        >
          {isLoading ? (
            <>
              Update Name <Spinner size="xs" ml={2} /> {/* Spinner next to text */}
            </>
          ) : (
            'Update Name'
          )}
        </Button>
        <Link href={"/settings/manage-store"} fontSize="sm" color="blue.500">More Options</Link>
      </HStack>

     

      {/* Success message */}
      {successMessage && (
        <Text color="green.500" fontSize="sm" mt={2}>
          {successMessage}
        </Text>
      )}

      {/* Error message */}
      {errorMessage && (
        <Text color="red.500" fontSize="sm" mt={2}>
          {errorMessage}
        </Text>
      )}
    </Box>
  );
};

export default ShopNameStep;