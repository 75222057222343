import { Box, Grid, Text, VStack, Image, Button } from '@chakra-ui/react';
import { useState } from 'react';

const templates = [
  { id: 1, name: "Classic", imageUrl: "https://via.placeholder.com/100" },
  { id: 2, name: "Modern", imageUrl: "https://via.placeholder.com/100" },
  { id: 3, name: "Elegant", imageUrl: "https://via.placeholder.com/100" },
  { id: 4, name: "Minimal", imageUrl: "https://via.placeholder.com/100" }
];

const TemplateSelection = ({ onSelectTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template.id);
    onSelectTemplate(template); // Callback to set the template in the main flow
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Text fontWeight="bold" fontSize="lg">Choose a Template</Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        {templates.map((template) => (
          <Box
            key={template.id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={4}
            bg={selectedTemplate === template.id ? "blue.100" : "gray.50"}
            onClick={() => handleTemplateClick(template)}
            cursor="pointer"
            boxShadow={selectedTemplate === template.id ? "outline" : "sm"}
          >
            <Image src={template.imageUrl} alt={template.name} mb={2} />
            <Text fontWeight="semibold">{template.name}</Text>
            {selectedTemplate === template.id && (
              <Button size="sm" mt={2} colorScheme="blue">
                Selected
              </Button>
            )}
          </Box>
        ))}
      </Grid>
    </VStack>
  );
};

export default TemplateSelection;