import {
    Box,
    Button,
    Text,
    VStack,
    Heading,
    HStack,
    IconButton,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    DrawerCloseButton,
    Input,
    Textarea,
    Select,
    FormControl,
    FormLabel,
    FormHelperText,
    Divider,
    StackDivider,
    useToast,
    Spinner,
    Icon,
    useColorModeValue
} from '@chakra-ui/react';
import { PenSquare } from 'lucide-react';
import { useState, useEffect } from 'react';
import { EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import apiHandler from '../../../../services/apiHandler';
import { GET_STORE_DETAILS, UPDATE_STORE_DETAILS } from '../../../../services/apiConfig';

import StoreDetailsForm from './StoreDetailsForm';
import BrandingForm from './BrandingForm';
import StoreSettingsForm from './StoreSettingsForm';
import { Store } from 'lucide-react';

const StoreSettings = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const [editingSection, setEditingSection] = useState(null);

    const [storeDetails, setStoreDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const borderColor = 'gray.200';
    useEffect(() => {
        // Fetch store details from API
        const fetchStoreDetails = async () => {
            try {
                const response = await apiHandler({ options: GET_STORE_DETAILS });
                setStoreDetails(response.data);
                console.log("Store details:", response.data);
            } catch (error) {
                console.error("Error fetching store details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStoreDetails();
    }, []);


    // const handleSave = (updatedSection) => {
    //     setStoreDetails({ ...storeDetails, ...updatedSection });
    //     setEditingSection(null);
    // };

    const handleSave = async (updatedSection) => {
        console.log("Updating settings:", updatedSection);
        try {
            setLoading(true);
            const updatedDetails = { ...storeDetails, ...updatedSection };
            await apiHandler({ options: UPDATE_STORE_DETAILS, data: updatedDetails });
            setStoreDetails(updatedDetails);
            toast({
                title: "Settings updated successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setEditingSection(null);
        } catch (error) {
            console.error("Error updating settings:", error);
            toast({
                title: "Failed to update settings.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };
    const closeDrawer = () => setEditingSection(null);

    if (loading) {
        return (
            <Box p={6} textAlign="center">
                <Spinner />
            </Box>
        );
    }

    const renderEditingForm = (section) => {
        switch (section) {
            case "storeDetails":
                return (
                    <StoreDetailsForm storeDetails={storeDetails} setStoreDetails={handleSave} />
                );
            case "branding":
                return (
                    <BrandingForm storeDetails={storeDetails} setStoreDetails={handleSave} />
                );
            case "storeSettings":
                return (
                    <StoreSettingsForm storeDetails={storeDetails} setStoreDetails={handleSave} />
                );
            default:
                return null;
        }
    };

    const SectionHeading = ({ sectionId, title, description }) => {
        return (
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <Heading size="md">{title}</Heading>
                    <Text fontSize="sm" color="gray.500">{description}</Text>
                </Box>
                <Icon as={PenSquare} boxSize={5} color="gray.500" cursor="pointer" onClick={() => setEditingSection(sectionId)} />
            </Box>
        );
    };

    const SectionItem = ({ title, value }) => {
        return (
            <Box>
                <Text fontSize="sm" fontWeight="medium">{title}</Text>
                <Text fontSize="sm" color="gray.500">{value}</Text>
            </Box>
        );
    };

    return (
        <Box p={6}>
            <Heading as="h2" size="lg" mb={1}> Store Settings</Heading>
            <Text fontSize="sm" color="gray.500" mb={6}>Manage your store details, branding, and settings</Text>
            <VStack align="stretch" spacing={6} divider={<StackDivider />}>
                {/* Render Store Details */}
                <Box borderWidth="1px" borderRadius="lg" p={6} borderColor={borderColor}>
                    <VStack align="stretch" spacing={4}>
                        <SectionHeading sectionId="storeDetails" title="Store Details" description="Manage your store name, URL and billing information" />
                        <SectionItem title="Store Name" value={storeDetails.store_name} />
                        <SectionItem title="Store URL" value={storeDetails.storeURL} />
                        <SectionItem title="Billing Address" value={storeDetails.billingAddress} />
                    </VStack>
                </Box>
                {/* Render Branding */}
                <Box borderWidth="1px" borderRadius="lg" p={6} borderColor={borderColor}>
                    <VStack align="stretch" spacing={4}>
                        <SectionHeading sectionId="branding" title="Branding" description="Customize your store's brand identity" />
                        <SectionItem title="Logo" value={storeDetails.logo} />
                        <SectionItem title="Tagline" value={storeDetails.tagline} />
                        <SectionItem title="Short Description" value={storeDetails.shortDescription} />
                        <SectionItem title="Long Description" value={storeDetails.store_description} />
                    </VStack>
                </Box>
                {/* Render Store Settings */}
                <Box borderWidth="1px" borderRadius="lg" p={6} borderColor={borderColor}>
                    <VStack align="stretch" spacing={4}>
                        <SectionHeading sectionId="storeSettings" title="Store Settings" description="Configure your store's operational settings" />
                        <SectionItem title="Currency" value={storeDetails.currency} />
                        <SectionItem title="Weight Unit" value={storeDetails.weightUnit} />
                        <SectionItem title="Timezone" value={storeDetails.timezone} />
                        <SectionItem title="Order Id Format" value={storeDetails.orderIDFormat} />
                        <SectionItem title="Order Processing" value={storeDetails.orderProcessing} />
                    </VStack>
                </Box>
            </VStack>

            <Drawer isOpen={!!editingSection} placement="right" onClose={closeDrawer} size="md">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth="1px">Edit {editingSection}</DrawerHeader>
                    <DrawerBody>{renderEditingForm(editingSection)}</DrawerBody>
                </DrawerContent>
            </Drawer>

            <Button size="sm" variant="link" colorScheme="blue" mt={6} onClick={() => navigate("/settings")}>
                Back to Settings
            </Button>
        </Box>
    );
};

export default StoreSettings;