import React, { useState } from 'react';
import {
  Box,
  Input,
  Button,
  VStack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Text,
  Badge,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { FiSearch, FiDollarSign, FiShoppingCart } from 'react-icons/fi';

const DomainSearch = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [purchasing, setPurchasing] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const toast = useToast();

  const handleSearch = async () => {
    if (!query) {
      toast({ title: 'Please enter a domain name.', status: 'warning' });
      return;
    }

    try {
      setLoading(true);
      setResults([]);
      const extensions = ['.com', '.net', '.org']; // Add more extensions as needed
      const response = await axios.get('http://localhost/api/v1/domain/check', {
        params: { query, extensions: extensions.join(',') },
      });
      console.log(response);
      setResults(response.data.data.results);
    } catch (error) {
      console.error('Error searching for domains:', error);
      toast({ title: 'Failed to fetch domain availability.', status: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = async (domain, provider) => {
    try {
      setPurchasing(true);
      const contactInfo = {
        nameFirst: 'John',
        nameLast: 'Doe',
        email: 'john.doe@example.com',
        phone: '+1234567890',
        addressMailing: {
          address1: '123 Main St',
          city: 'New York',
          state: 'NY',
          postalCode: '10001',
          country: 'US',
        },
      };

      await axios.post('/api/domain/purchase', {
        domain,
        provider,
        contactInfo,
      });

      toast({
        title: `Domain purchased successfully via ${provider}!`,
        status: 'success',
      });
      setSelectedDomain(domain);
    } catch (error) {
      console.error('Error purchasing domain:', error);
      toast({
        title: 'Failed to purchase domain.',
        description: error.message,
        status: 'error',
      });
    } finally {
      setPurchasing(false);
    }
  };

  return (
    <Box p={6} boxShadow="lg" bg="white" borderRadius="md">
      <VStack spacing={6} align="stretch">
        {/* Search Section */}
        <HStack spacing={4} align="center">
          <Input
            placeholder="Enter domain name (e.g., example)"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            size="md"
          />
          <Button
            leftIcon={<FiSearch />}
            colorScheme="teal"
            onClick={handleSearch}
            isLoading={loading}
          >
            Search
          </Button>
        </HStack>

        {/* Search Results */}
        {loading && (
          <HStack justify="center">
            <Spinner size="lg" />
            <Text>Searching for domains...</Text>
          </HStack>
        )}

        {results.length > 0 && (
          <Box>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Domain</Th>
                  <Th>Provider</Th>
                  <Th>Availability</Th>
                  <Th>Price</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {results.map((result, index) => (
                  <Tr key={index}>
                    <Td>{result.domain}</Td>
                    <Td>
                      <Badge colorScheme={result.provider === 'GoDaddy' ? 'green' : 'blue'}>
                        {result.provider}
                      </Badge>
                    </Td>
                    <Td>
                      <Text color={result.available ? 'teal.500' : 'red.500'}>
                        {result.available ? 'Available' : 'Unavailable'}
                      </Text>
                    </Td>
                    <Td>
                      {result.price ? (
                        <>
                          <FiDollarSign /> {result.price} USD
                        </>
                      ) : (
                        'N/A'
                      )}
                    </Td>
                    <Td>
                      {result.available ? (
                        <Button
                          size="sm"
                          colorScheme="teal"
                          leftIcon={<FiShoppingCart />}
                          onClick={() => handlePurchase(result.domain, result.provider)}
                          isLoading={purchasing && selectedDomain === result.domain}
                        >
                          Buy Now
                        </Button>
                      ) : (
                        <Text color="gray.500">Not Available</Text>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}

        {/* No Results */}
        {results.length === 0 && !loading && (
          <Text color="gray.500" textAlign="center">
            Enter a domain name and click "Search" to check availability.
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default DomainSearch;