import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { SuperTokensWrapper } from "supertokens-auth-react";
import { SessionAuth } from "supertokens-auth-react/recipe/session";

import { initSuperTokens } from "./auth/superTokensConfig";

import SellerRoutes from "./routes/SellerPortalRoutes";
import WebsiteRoutes from "./routes/WebsiteRoutes";

import customTheme from "./theme";

import Consumer from "./pages/consumer";
import CheckoutPage from "./pages/consumer/CheckoutPage";
import UserProfile from "./pages/SellerPortal/UserProfile";
import Template1 from "./pages/templates/shop/Template1";
import OnlineStore from "./pages/OnlineStore";

// initialize SuperTokens
initSuperTokens();

export default function App() {

    // Main return rendering
    return (
        <SuperTokensWrapper>
            <ChakraProvider theme={customTheme}>
                <Router>
                    <Routes>
                        {/* <Route index element={<ComingSoon />} /> */}
                        <Route path="/*" element={<WebsiteRoutes />} />
                        <Route path="/app/*" element={<SellerRoutes />} />

                        {/* {WebsiteRoutes} */}
                        <Route path="/template1" element={<Template1 />} />
                        <Route path="/user-profile" element={<SessionAuth><UserProfile /></SessionAuth>} />
                        <Route path="/consumer" element={<Consumer />} />
                        <Route path="/checkout" element={<CheckoutPage />} />
                        <Route path="/shop/:store_name" element={<OnlineStore />} />
                    </Routes>
                    {/* Loading indicator while session is being checked */}
                    {/* {(isSessionLoading || isUserLoading) && (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                        <Spinner size="xl" />
                        </Box>
                    )} */}


                </Router>
            </ChakraProvider>
        </SuperTokensWrapper>
    );
}
