import React, { useEffect, useState } from 'react';
import { Avatar, Box, Flex, Image, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue, VStack, MenuDivider } from '@chakra-ui/react';
import { FiBell, FiChevronDown, FiMenu } from 'react-icons/fi';

import { useUser } from "../../context/UserContext";
import logo from '../../assets/logo.png';

const NotificationMenu = () => (
    <Menu>
        <MenuButton as={IconButton} icon={<FiBell />} variant="ghost" aria-label="Notifications" />
        <MenuList>
            <MenuItem>New Order Received</MenuItem>
            <MenuItem>System Update Available</MenuItem>
            <MenuDivider />
            <MenuItem>View All Notifications</MenuItem>
        </MenuList>
    </Menu>
);

const UserMenu = ({ user }) => {
    return (
        <Menu>
        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
            <HStack>
                <Avatar
                    size={'sm'}
                    src={user.avatar}
                />
                <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2">
                    <Text fontSize="sm">{user?.name || "Guest"}</Text>
                    <Text fontSize="xs" color="gray.600">{user.role}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                </Box>
            </HStack>
        </MenuButton>
        <MenuList
            bg={useColorModeValue('white', 'gray.900')}
            borderColor={useColorModeValue('gray.200', 'gray.700')}>
            <MenuItem>Profile</MenuItem>
            <MenuItem>Settings</MenuItem>
            <MenuItem>Billing</MenuItem>
            <MenuDivider />
            <MenuItem>Sign out</MenuItem>
        </MenuList>
    </Menu>
    );
};
const TopHeaderNav = ({ onOpen, ...rest }) => {

    const { user } = useUser();

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 0);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="80px"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            position="sticky" /* Ensures the header stays visible on scroll */
            top="0"
            zIndex="10" /* Keeps it above other elements */
            overflow="hidden" /* Prevents child overflow issues */
            role="banner" aria-label="Header"
            boxShadow={scrolled ? "md" : "none"}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="Open Navigation Menu"
                icon={<FiMenu />}
            />
            <Box
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold"
            >
                <Image w="150px" src={logo} alt='MiniTaka : Sell Anything' />
            </Box>

            <HStack spacing={{ base: '0', md: '6' }}>
                <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
                <Flex alignItems={'center'}>
                <UserMenu user={user} />
                </Flex>
            </HStack>
        </Flex>
    )
}

export default TopHeaderNav;