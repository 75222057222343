import { chakra, Text, VStack } from '@chakra-ui/react';

const PricingHeader = () => (
  <VStack spacing={4} mb={8}>
    <chakra.h2 
      fontSize={{ base: "4xl", md: "5xl" }} 
      fontWeight="bold" 
      textAlign="center"
      bgGradient="linear(to-r, teal.500, teal.300)"
      bgClip="text"
    >
      Simple and affordable pricing
    </chakra.h2>
    <Text 
      color="gray.600" 
      textAlign="center" 
      maxW="2xl"
      fontSize="lg"
    >
      Choose the perfect plan for your business needs
    </Text>
  </VStack>
);

export default PricingHeader;