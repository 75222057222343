import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';

const ImportProductsModal = ({ isOpen, onClose }) => {

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Handle file processing logic here (e.g., reading CSV, sending to backend)
      console.log("File uploaded:", file);
    }
  };

  const downloadSampleFile = () => {
    // Trigger download for the sample file
    const sampleData = 'name,price,description,category\nProduct1,10.99,Sample description,Category1';
    const blob = new Blob([sampleData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'sample_product.csv');
    a.click();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Products</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="stretch" spacing={4}>
            <Text color="gray.600" fontSize="sm">
              Upload a CSV file to import your products into the store.
            </Text>
            <Input type="file" accept=".csv" onChange={handleFileUpload} />
            <Button variant="link" colorScheme="blue" onClick={downloadSampleFile}>
              Download Sample CSV File
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImportProductsModal;