import React, { useState } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Button,
} from '@chakra-ui/react';
import { FiTrash, FiUserPlus } from 'react-icons/fi';
import BackToSettings from '../BackToSettings';

const permissionsList = [
  { id: 'manage-products', label: 'Manage Products', description: 'Add, edit, or remove products.' },
  { id: 'view-orders', label: 'View Orders', description: 'Access order details.' },
  { id: 'manage-settings', label: 'Manage Settings', description: 'Change store configurations.' },
];

const UserPermissions = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newMember, setNewMember] = useState({ email: '', role: 'Viewer', permissions: [] });

  const handleAddMember = () => {
    if (!newMember.email) return alert('Email is required!');
    setTeamMembers([...teamMembers, newMember]);
    setNewMember({ email: '', role: 'Viewer', permissions: [] });
    onClose();
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={4}>
        User Permissions
      </Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>Role</Th>
            <Th>Permissions</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {teamMembers.map((member, index) => (
            <Tr key={index}>
              <Td>{member.email}</Td>
              <Td>{member.role}</Td>
              <Td>{member.permissions.join(', ') || 'None'}</Td>
              <Td>
                <IconButton
                  icon={<FiTrash />}
                  aria-label="Remove team member"
                  onClick={() => setTeamMembers(teamMembers.filter((_, i) => i !== index))}
                  colorScheme="red"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Button leftIcon={<FiUserPlus />} colorScheme="teal" onClick={onOpen} mt={4}>
        Invite User
      </Button>
      {/* Invite User Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Invite New User</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  value={newMember.email}
                  onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
                  placeholder="Enter user email"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Role</FormLabel>
                <Select
                  value={newMember.role}
                  onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
                >
                  <option value="Admin">Admin</option>
                  <option value="Editor">Editor</option>
                  <option value="Viewer">Viewer</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Permissions</FormLabel>
                {permissionsList.map((perm) => (
                  <Box key={perm.id}>
                    <input
                      type="checkbox"
                      checked={newMember.permissions.includes(perm.label)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setNewMember((prev) => ({
                          ...prev,
                          permissions: isChecked
                            ? [...prev.permissions, perm.label]
                            : prev.permissions.filter((p) => p !== perm.label),
                        }));
                      }}
                    />
                    <Text ml={2}>{perm.label}</Text>
                    <Text fontSize="xs" color="gray.500">
                      {perm.description}
                    </Text>
                  </Box>
                ))}
              </FormControl>
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme="teal" onClick={handleAddMember}>
              Add User
            </Button>
            <Button variant="outline" onClick={onClose} ml={3}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default UserPermissions;