const APP_ROUTES = {
    VIEW_PRODUCTS: "/app/products",
    ADD_PRODUCT: "/app/products?mode=add",
    IMPORT_PRODUCT: "/app/products?mode=import",
    ADD_SAMPLE_PRODUCT: "/app/products?mode=sample-product",
    EDIT_PRODUCT: "/app/products?mode=edit",
    

    // Settings Subpages
    MANAGE_STORE_SETTINGS: "/app/settings/manage-store",
    DELIVERY_OPTIONS_SETTINGS: "/app/settings/delivery-options",
    PAYMENT_SETTINGS: "/app/settings/payment-settings",
    SEO_SETTINGS: "/app/settings/seo-settings",
    USER_PERMISSIONS_SETTINGS: "/app/settings/user-permissions",
    NOTIFICATION_SETTINGS: "/app/settings/notification-settings",
    STORE_TIMINGS_SETTINGS: "/app/settings/store-timings",
    DOMAIN_OPTIONS_SETTINGS: "/app/settings/domain-options",
    SUPPORT_DETAILS_SETTINGS: "/app/settings/support-details",
    ORDER_ACCEPTANCE_SETTINGS: "/app/settings/order-acceptance",
    TAX_SETTINGS: "/app/settings/tax-settings",
    STORE_POLICIES: "/app/settings/store-policies",
    INTEGRATIONS_SETTINGS: "/app/settings/integrations-settings",
};

export default APP_ROUTES;