import React, { useState } from 'react';
import { Box, Heading, Text, Img, Flex, Center, HStack, useColorModeValue } from '@chakra-ui/react';
import { FiShoppingCart } from 'react-icons/fi';

const PostWithLike = ({ name, seller, description, price, image, onAddToCart }) => {
  const [addedToCart, setAddedToCart] = useState(false);

  const handleAddToCart = () => {
    setAddedToCart(true);
    onAddToCart({ name, price }); // Pass product name and price to the parent
  };

  return (
    <Center py={6}>
      <Box
        w="200px" // Fixed width
        my={5}
        mx={[0, 5]}
        overflow={'hidden'}
        bg="white"
      >
        <Box h={'150px'}>
          <Img
            src={image}
            objectFit="cover"
            h="full"
            w="full"
            alt={'Product Image'}
          />
        </Box>
        <Box p={4}>
          <Heading color={'black'} fontSize={'lg'} noOfLines={1}>
            {name}
          </Heading>
          <Text fontSize={'xs'} color={'gray.600'}>
            by {seller}
          </Text>

          <Text color={'gray.500'} fontSize={'sm'} noOfLines={2}>
            {description}
          </Text>
        </Box>
        <HStack color="black">
          <Flex p={4} alignItems="center" justifyContent={'space-between'} w="full">
            <Text fontSize={'md'} fontWeight={'semibold'}>
              Price: ₹{price}  {/* Display rupee symbol */}
            </Text>
          </Flex>

          <Flex
            p={4}
            alignItems="center"
            justifyContent={'space-between'}
            cursor="pointer"
            onClick={handleAddToCart}  // Add to cart action on click
          >
            <FiShoppingCart fontSize={'24px'} />
          </Flex>
        </HStack>
      </Box>
    </Center>
  );
};

export default PostWithLike;
