import React, { useState } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Flex, Switch, Text, Tooltip, } from '@chakra-ui/react';
import { FiMoreVertical, FiEye, FiEdit } from 'react-icons/fi';
import { BsFillPersonFill } from 'react-icons/bs'; // Icon for custom/manual orders
import { useNavigate } from 'react-router-dom';

import OrderRow from './OrderRow';

const OrdersTable = ({ orders }) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    // Set number of pages
    // setTotalPages(Math.ceil(Object.values(orders) / pageSize));
    const startIndex = (currentPage - 1) * pageSize;
    const displayedOrders = Object.values(orders).slice(startIndex, startIndex + pageSize);

    const handleViewOrder = (orderId) => {
        navigate(`/orders/${orderId}`);
    };

    const handleEditOrder = (orderId) => {
        navigate(`/orders/${orderId}/edit`);
    };

    const TableHead = () => {
        return (
            <Thead>
                <Tr>
                    <Th>Order ID</Th>
                    <Th>Customer Info</Th>
                    <Th>Date</Th>
                    <Th>Amount</Th>
                    <Th>Status</Th>
                    <Th>Actions</Th>
                </Tr>
            </Thead>
        );
    };

    return (
        <Box overflowX="auto">
            <Table variant="simple" size="sm">
                <TableHead />
                <Tbody>
                    {Object.values(orders).map((order) => (
                        <OrderRow key={order.id} order={order} />
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default OrdersTable;