import { Stack, Link, Text } from "@chakra-ui/react";

const sections = [
  {
    title: "Product",
    links: [
      { label: "Features", href: "#features" },
      { label: "Pricing", href: "#pricing" },
      // { label: "Updates", href: "#updates" }
    ]
  },
  {
    title: "Company",
    links: [
      { label: "About Us", href: "/company/about" },
      // { label: "Careers", href: "/company/careers" },
      // { label: "Press", href: "/company/press" },
      { label: "Contact", href: "/company/contact" }
    ]
  },
  // {
  //   title: "Resources",
  //   links: [
  //     // { label: "Blog", href: "/blog" },
  //     { label: "Help Center", href: "/resources/help" },
  //     { label: "Tutorials", href: "/resources/tutorials" },
  //     // { label: "API Reference", href: "/api" }
  //   ]
  // },
  {
    title: "Legal",
    links: [
      { label: "Privacy", href: "/legal/privacy-policy" },
      { label: "Terms", href: "/legal/terms-and-conditions" },
      // { label: "Security", href: "/security" },
      // { label: "Cookies", href: "/cookies" }
    ]
  }
];

export const FooterLinks = () => (
  <Stack
    direction={{ base: "column", md: "row" }}
    spacing={{ base: 10, md: 20 }}
    justify="space-between"
    py={10}
  >
    {sections.map((section) => (
      <Stack key={section.title} spacing={4}>
        <Text fontWeight="semibold" color="gray.700">
          {section.title}
        </Text>
        {section.links.map((link) => (
          <Link
            key={link.label}
            href={link.href}
            color="gray.500"
            _hover={{ color: "teal.500" }}
            fontSize="sm"
          >
            {link.label}
          </Link>
        ))}
      </Stack>
    ))}
  </Stack>
);