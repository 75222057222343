import { Container, SimpleGrid, Box } from '@chakra-ui/react';
import PricingHeader from './PricingHeader';
import PricingCard from './PricingCard';
import PricingSwitch from './PricingSwitch';
import { usePricing } from './usePricing';

const Pricing = () => {
  const { plans, isYearly, setIsYearly } = usePricing();
  const numberOfPlans = plans.length;

  return (
    <Box bg="gray.50" py={16} id="pricing">
      <Container maxW="7xl" px={{ base: 4, md: 8 }}>
        <PricingHeader />
        <PricingSwitch isYearly={isYearly} onChange={setIsYearly} />
        
        <SimpleGrid 
          columns={{ base: 1, md: numberOfPlans === 2 ? 2 : 3 }} 
          spacing={{ base: 6, lg: 8 }} 
          alignItems="flex-start"
        >
          {plans.map((plan, index) => (
            <PricingCard 
              key={index} 
              {...plan} 
              isPopular={plan.title === 'Growth'}
              billingPeriod={isYearly ? 'year' : 'month'}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Pricing;