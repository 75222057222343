import { Tr, Td, Button, Menu, MenuButton, MenuList, MenuItem, IconButton, Tooltip, Box, } from '@chakra-ui/react';
import { MoreHorizontal } from 'lucide-react';
import { BsFillPersonFill } from 'react-icons/bs';

import { renderPrice, formatDate } from '../../../helpers/utils';

export default function OrderRow({ order }) {
    console.log(order);;
    return (
        <Tr key={order.id}>
            <Td>
                <div>
                    <div>{order.order_code}</div>
                    {order.isCustom && (
                        <Tooltip label="Custom Order" placement="top">
                            <Box as="span" color="teal.500" mr={2}>
                                <BsFillPersonFill />
                            </Box>
                        </Tooltip>
                    )}
                    
                </div>
            </Td>
            <Td>
                <div>{order.customer_name}</div>
                <div className="text-sm text-gray-500">#{order.customer_phone}</div>
            </Td>
            <Td>
                <div className="">{formatDate(order.created_at, "date")}</div>
                <div text-size="sm">{formatDate(order.created_at, "time")}</div>
            </Td>
            <Td>{renderPrice(order.total_price)}</Td>
            <Td>
                <Button
                    size="sm"
                    colorScheme={order.status === 'New' ? 'blue' : 'gray'}
                    variant="solid"
                    borderRadius="full"
                >
                    {order.status}
                </Button>
            </Td>
            <Td textAlign="right">
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MoreHorizontal size={20} />}
                        variant="ghost"
                        size="sm"
                    />
                    <MenuList>
                        <MenuItem>View Details</MenuItem>
                        <MenuItem>Edit Order</MenuItem>
                        <MenuItem>Cancel Order</MenuItem>
                    </MenuList>

                    {/* 
                     <Flex>
                                    <IconButton
                                        aria-label="View order"
                                        icon={<FiEye />}
                                        variant="ghost"
                                        onClick={() => handleViewOrder(order.id)}
                                        mr={2}
                                    />
                                    <IconButton
                                        aria-label="Edit order"
                                        icon={<FiEdit />}
                                        variant="ghost"
                                        onClick={() => handleEditOrder(order.id)}
                                    />
                                    <IconButton
                                        aria-label="More options"
                                        icon={<FiMoreVertical />}
                                        variant="ghost"
                                    />
                                </Flex>
                    */}
                </Menu>
            </Td>
        </Tr>
    );
}