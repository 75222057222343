import React from "react";
import { Box, Flex, Heading, Avatar, Text, Button, Stack, Divider, Icon, VStack } from "@chakra-ui/react";
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaEdit } from "react-icons/fa";

const UserProfile = () => {
    return (
        <Box maxW="900px" mx="auto" p={8} bg="white" boxShadow="md" borderRadius="lg">
            {/* User Basic Info Section */}
            <Flex align="center" direction={{ base: "column", md: "row" }} textAlign={{ base: "center", md: "left" }}>
                <Avatar size="2xl" name="John Doe" src="https://bit.ly/dan-abramov" />
                <Box ml={{ md: 6 }} mt={{ base: 4, md: 0 }}>
                    <Heading as="h2" size="lg">John Doe</Heading>
                    <Text fontSize="md" color="gray.500">Software Engineer</Text>
                    <Text mt={2} fontSize="sm" color="gray.600">
                        Passionate developer with experience in full-stack development and a keen interest in front-end design.
                    </Text>
                    <Button mt={4} colorScheme="teal" leftIcon={<FaEdit />}>Edit Profile</Button>
                </Box>
            </Flex>

            <Divider my={6} />

            {/* Contact Information */}
            <VStack align="start" spacing={4}>
                <Heading as="h3" size="md">Contact Information</Heading>
                <Flex align="center">
                    <Icon as={FaEnvelope} color="gray.500" boxSize={5} mr={2} />
                    <Text fontSize="sm">johndoe@example.com</Text>
                </Flex>
                <Flex align="center">
                    <Icon as={FaPhone} color="gray.500" boxSize={5} mr={2} />
                    <Text fontSize="sm">+1 (555) 123-4567</Text>
                </Flex>
                <Flex align="center">
                    <Icon as={FaMapMarkerAlt} color="gray.500" boxSize={5} mr={2} />
                    <Text fontSize="sm">San Francisco, CA</Text>
                </Flex>
            </VStack>

            <Divider my={6} />

            {/* About Section */}
            <VStack align="start" spacing={4}>
                <Heading as="h3" size="md">About</Heading>
                <Text fontSize="sm" color="gray.600">
                    John is a skilled software engineer with over 5 years of experience. His expertise spans full-stack development, including backend services, front-end frameworks, and cloud infrastructure. John is passionate about building scalable solutions and enjoys exploring new technologies.
                </Text>
            </VStack>
        </Box>
    );
};

export default UserProfile;