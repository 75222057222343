// EmptyState.js
import React from 'react';
import { Box, Flex, Text, Button, Image, VStack } from '@chakra-ui/react';

const EmptyState = ({ imageSrc, title, description, buttonText, onButtonClick }) => {
  return (
    <Flex align="center" justify="center" h="100%" w="100%">
      <VStack spacing={4} align="center">
        <Image src={imageSrc} alt={title} boxSize="120px" />
        <Text fontSize="lg" fontWeight="bold">{title}</Text>
        <Text fontSize="sm" color="gray.600" textAlign="center">{description}</Text>
        {buttonText && (
          <Button colorScheme="blue" onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </VStack>
    </Flex>
  );
};

export default EmptyState;
