import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Switch,
  NumberInput,
  NumberInputField,
  Button,
  useToast,
  HStack,
  Text,
  Divider,
} from '@chakra-ui/react';
import BackToSettings from '../BackToSettings';

const DeliveryOptions = () => {
  const [deliverySettings, setDeliverySettings] = useState({
    selfPickup: true,
    freeDelivery: false,
    deliveryWithinRange: false,
    cityDelivery: false,
    stateDelivery: false,
    nationalDelivery: false,
    internationalDelivery: false,
    deliveryFees: [],
  });

  const [newFee, setNewFee] = useState({ min: '', max: '', fee: '' });
  const toast = useToast();

  const addFeeRange = () => {
    if (!newFee.min || !newFee.max || !newFee.fee) {
      toast({
        title: 'Error',
        description: 'Please provide all fields for the fee range.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setDeliverySettings((prev) => ({
      ...prev,
      deliveryFees: [...prev.deliveryFees, newFee],
    }));

    setNewFee({ min: '', max: '', fee: '' });
    toast({
      title: 'Delivery range added',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSave = () => {
    toast({
      title: 'Delivery settings saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('Delivery Settings:', deliverySettings);
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={6}>
        Delivery Options
      </Heading>
      <VStack spacing={6} align="stretch">
        {/* Self Pickup */}
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable Self Pickup</FormLabel>
          <Switch
            isChecked={deliverySettings.selfPickup}
            onChange={(e) =>
              setDeliverySettings({ ...deliverySettings, selfPickup: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>

        {/* Free Delivery */}
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable Free Delivery</FormLabel>
          <Switch
            isChecked={deliverySettings.freeDelivery}
            onChange={(e) =>
              setDeliverySettings({ ...deliverySettings, freeDelivery: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        {deliverySettings.freeDelivery && (
          <FormControl>
            <FormLabel>Set Free Delivery Range (in km)</FormLabel>
            <HStack>
              <NumberInput
                min={0}
                value={newFee.min}
                onChange={(value) => setNewFee({ ...newFee, min: value })}
              >
                <NumberInputField placeholder="Min (km)" />
              </NumberInput>
              <NumberInput
                min={0}
                value={newFee.max}
                onChange={(value) => setNewFee({ ...newFee, max: value })}
              >
                <NumberInputField placeholder="Max (km)" />
              </NumberInput>
              <NumberInput
                min={0}
                value={newFee.fee}
                onChange={(value) => setNewFee({ ...newFee, fee: value })}
              >
                <NumberInputField placeholder="Fee (₹)" />
              </NumberInput>
              <Button onClick={addFeeRange} colorScheme="teal">
                Add
              </Button>
            </HStack>
          </FormControl>
        )}

        {/* Delivery Zones */}
        <Divider my={4} />
        <Heading size="md">Delivery Zones</Heading>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable City Delivery</FormLabel>
          <Switch
            isChecked={deliverySettings.cityDelivery}
            onChange={(e) =>
              setDeliverySettings({ ...deliverySettings, cityDelivery: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable State Delivery</FormLabel>
          <Switch
            isChecked={deliverySettings.stateDelivery}
            onChange={(e) =>
              setDeliverySettings({ ...deliverySettings, stateDelivery: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable National Delivery</FormLabel>
          <Switch
            isChecked={deliverySettings.nationalDelivery}
            onChange={(e) =>
              setDeliverySettings({ ...deliverySettings, nationalDelivery: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable International Delivery</FormLabel>
          <Switch
            isChecked={deliverySettings.internationalDelivery}
            onChange={(e) =>
              setDeliverySettings({ ...deliverySettings, internationalDelivery: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>

        {/* Save Button */}
        <Button colorScheme="teal" onClick={handleSave}>
          Save Settings
        </Button>
      </VStack>
    </Box>
  );
};

export default DeliveryOptions;