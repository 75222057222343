import { NavLink as Link } from 'react-router-dom';
import { Flex, Icon } from '@chakra-ui/react';

const navLinkStyles = {
    baseStyle: {
      p: 1,
      mx: 1,
      my:1,
      borderRadius: "lg",
      cursor: "pointer",
      alignItems: "center",
      _hover: { bg: "cyan.400", color: "white" },
    },
    activeStyle: { bg: "cyan.400", color: "white" },
  };

const NavItem = ({ icon, href, children, ...rest }) => {
    return (
        <Link
            to={href}
            className={({ isActive }) =>
                `nav-link ${isActive ? "active" : ""}`
            }
            style={{ textDecoration: "none" }}
        >
            <Flex sx={navLinkStyles.baseStyle} {...rest}>
                {
                    icon && <Icon mr="4" fontSize="16" _groupHover={{ color: 'white' }} as={icon} />
                }
                {children}
            </Flex>
        </Link>
    )
}

export default NavItem;