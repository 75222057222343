import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Image,
    Button,
    IconButton,
    Spinner,
    HStack,
    VStack,
    Divider,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    CloseButton,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Review from '../../../../components/Review';

const ProductDetails = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);

    const { isOpen, onOpen, onClose } = useDisclosure(); // For modal
    const [selectedImage, setSelectedImage] = useState(null); // To track selected image for modal

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`http://localhost/api/v1/products/${productId}`);
                setProduct(response.data.data.data);
            } catch (error) {
                console.error('Failed to fetch product details', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [productId]);

    if (loading) return <Spinner size="xl" />;
    if (!product) return <Text>No product found.</Text>;

    const renderMainImage = () => {
        const mainImage = product.media_urls?.[0] || 'https://via.placeholder.com/800x600';
        return (
            <Image
                src={mainImage}
                alt={product.title}
                borderRadius="md"
                mb={4}
                onClick={() => {
                    setSelectedImage(mainImage);
                    onOpen();
                }}
                cursor="pointer"
            />
        );
    };

    const renderGallery = () => {
        return (
            <Flex overflowX="scroll" gap={2} py={2}>
                {product.media_urls?.length ? (
                    product.media_urls.map((url, index) => (
                        <Image
                            key={index}
                            src={url}
                            alt={`Image ${index + 1}`}
                            borderRadius="md"
                            boxSize="100px"
                            cursor="pointer"
                            onClick={() => {
                                setSelectedImage(url);
                                onOpen();
                            }}
                        />
                    ))
                ) : (
                    <Image
                        src="https://via.placeholder.com/100"
                        alt="Dummy Image"
                        borderRadius="md"
                        boxSize="100px"
                    />
                )}
            </Flex>
        );
    };

    const renderAdditionalDetails = () => (
        <VStack align="left" spacing={2}>
            <Text fontSize="sm"><strong>SKU:</strong> {product.sku}</Text>
            <Text fontSize="sm"><strong>Category:</strong> {product.category || 'N/A'}</Text>
            <Text fontSize="sm"><strong>Status:</strong> {product.status || 'N/A'}</Text>
            <Text fontSize="sm"><strong>Inventory:</strong> {product.inventory_quantity || 'N/A'}</Text>
            <Text fontSize="sm"><strong>Dimensions:</strong> {JSON.stringify(product.dimensions) || 'N/A'}</Text>
            <Text fontSize="sm"><strong>Brand:</strong> {product.brand || 'N/A'}</Text>
            <Text fontSize="sm"><strong>Attributes:</strong></Text>
            <VStack align="left" pl={4}>
                {Object.entries(product.attributes || {}).map(([key, value]) => (
                    <Text key={key} fontSize="sm">{`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`}</Text>
                ))}
            </VStack>
        </VStack>
    );

    return (
        <>
            {/* Back to Products Button */}
            <Button onClick={() => navigate('/products')} variant="ghost" color="gray.500" mb={4}>
                <ChevronLeftIcon /> <Text fontSize="xs">Back to all Products</Text>
            </Button>

            {/* Tabbed Content */}
            <Tabs variant="enclosed" isLazy>
                <TabList>
                    <Tab>Details</Tab>
                    <Tab>Orders</Tab>
                    <Tab>Reviews</Tab>
                </TabList>

                <TabPanels>
                    {/* Details Tab */}
                    <TabPanel>
                        <Flex direction={{ base: 'column', lg: 'row' }} justifyContent="space-between" alignItems="flex-start">
                            {/* Product Images Section */}
                            <Box flex="2" mr={{ lg: 8 }} mb={{ base: 8, lg: 0 }}>
                                {renderMainImage()}
                                {renderGallery()}
                            </Box>

                            {/* Product Information Section */}
                            <Box flex="3">
                                <Heading as="h1" size="lg">{product.title}</Heading>
                                <Text fontSize="sm" color="gray.600" mb={2}>{product.subtitle || ''}</Text>
                                <Text fontSize="md" color="gray.700" mb={4}>{product.description}</Text>

                                {/* Pricing Section */}
                                <HStack spacing={4} mb={4}>
                                    <Text fontSize="2xl" color="teal.500" fontWeight="bold">
                                        ₹{product.discount_price || product.price}
                                    </Text>
                                    {product.discount_price && (
                                        <Text fontSize="sm" color="gray.500" textDecoration="line-through">
                                            ₹{product.price}
                                        </Text>
                                    )}
                                </HStack>

                                {/* Edit Button */}
                                <Button
                                    colorScheme="blue"
                                    onClick={() => navigate(`/products/?mode=edit&productId=${productId}`)}
                                >
                                    Edit Product
                                </Button>

                                {/* Additional Details */}
                                <Box bg="gray.100" p={4} borderRadius="md" mt={8}>
                                    <Heading as="h3" size="md" mb={2}>Additional Details</Heading>
                                    {renderAdditionalDetails()}
                                </Box>
                            </Box>
                        </Flex>
                    </TabPanel>

                    {/* Orders Tab */}
                    <TabPanel>
                        <Text>Orders related to this product will be shown here.</Text>
                    </TabPanel>

                    {/* Reviews Tab */}
                    <TabPanel>
                        <Heading as="h2" size="lg" mb={4}>
                            Customer Reviews
                        </Heading>
                        <VStack align="left" spacing={4}>
                            <Review productId={productId} />
                        </VStack>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {/* Modal for Image Viewer */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <CloseButton onClick={onClose} position="absolute" top="8px" right="12px" />
                        <Image src={selectedImage} alt="Expanded Image" borderRadius="md" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ProductDetails;