import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
} from 'react-icons/fi';

const dashboardTranslations = [
    { lang: 'en', label: 'Dashboard' },
    { lang: 'hi', label: 'डैशबोर्ड' },
    { lang: 'kn', label: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್' },
    { lang: 'ta', label: 'டாஷ்போர்டு' },
    { lang: 'te', label: 'డ్యాష్బోర్డ్' },
    { lang: 'bn', label: 'ড্যাশবোর্ড' },
  ];
  
  const productsTranslations = [
    { lang: 'en', label: 'Your Products' },
    { lang: 'hi', label: 'आपके उत्पाद' },
    { lang: 'kn', label: 'ನಿಮ್ಮ ಉತ್ಪನ್ನಗಳು' },
    { lang: 'ta', label: 'உங்கள் தயாரிப்புகள்' },
    { lang: 'te', label: 'మీ ఉత్పత్తులు' },
    { lang: 'bn', label: 'আপনার পণ্যসমূহ' },
  ];
  
  const ordersTranslations = [
    { lang: 'en', label: 'Orders' },
    { lang: 'hi', label: 'आदेश' },
    { lang: 'kn', label: 'ಆದೇಶಗಳು' },
    { lang: 'ta', label: 'ஆணைகள்' },
    { lang: 'te', label: 'ఆదేశాలు' },
    { lang: 'bn', label: 'অর্ডার' },
  ];
  
  const reportsTranslations = [
    { lang: 'en', label: 'Reports' },
    { lang: 'hi', label: 'रिपोर्ट' },
    { lang: 'kn', label: 'ವರದಿಗಳು' },
    { lang: 'ta', label: 'அறிக்கைகள்' },
    { lang: 'te', label: 'నివేదికలు' },
    { lang: 'bn', label: 'রিপোর্ট' },
  ];
  
  const settingsTranslations = [
    { lang: 'en', label: 'Settings' },
    { lang: 'hi', label: 'सेटिंग्स' },
    { lang: 'kn', label: 'ಸೆಟ್ಟಿಂಗ್ಗಳು' },
    { lang: 'ta', label: 'அமைப்புகள்' },
    { lang: 'te', label: 'సెట్టింగ్‌లు' },
    { lang: 'bn', label: 'সেটিংস' },
  ];


const LinkItems = [
    {
        name: 'Dashboard',
        id: 'dashboard',
        icon: FiHome,
        href: '/app/dashboard',
        description: "Overview of your account",
        ariaLabel: "Dashboard",
        translations: dashboardTranslations,
    },
    {
        name: 'Your Products',
        id: 'products',
        icon: FiCompass,
        href: '/app/products',
        ariaLabel: "Products",
        translations: productsTranslations
    },
    {
        name: 'Orders',
        id: 'orders',
        icon: FiStar,
        href: '/app/orders',
        ariaLabel: "Orders",
        translations: ordersTranslations,
    },
    {
        name: 'Reports',
        id: 'reports',
        icon: FiTrendingUp,
        href: '/app/reports',
        ariaLabel: "Reports",
        translations: reportsTranslations,
    },
    {
      name: 'Settings',
      id: 'settings',
      icon: FiSettings,
      href: '/app/settings',
      ariaLabel: "Settings",
      translations: settingsTranslations,
      // children: [
      //   { name: 'Domain Options', id: 'domain-options', href: '/settings/domain-options' },
      //   { name: 'Manage Store', id: 'manage-store', href: '/settings/manage-store' },
      //   { name: 'User Permissions', id: 'user-permissions', href: '/settings/user-permissions' },
      //   { name: 'Payment Settings', id: 'payment-settings', href: '/settings/payment-settings' },
      //   { name: 'Delivery Options', id: 'delivery-options', href: '/settings/delivery-options' },
      // ],
  },
];



export default LinkItems;