// pages/Home.js
import React from "react";
import { Outlet } from 'react-router-dom';
import { Box } from "@chakra-ui/react";
import SidebarWithHeader from "../components/Header";
import Footer from "../pages/SellerHome/Footer";

const LayoutSellerHome = () => {
    return (
        <Box bg="white">
            {/* Header with Login Button */}
            <SidebarWithHeader />
            <main>
                <Box  mx="auto">
                    <Outlet />
                </Box>
            </main>
            <Footer />
        </Box>
    );
};

export default LayoutSellerHome;
