export const MODES = {
    ADD: 'add',
    EDIT: 'edit',
    IMPORT: 'import',
    SAMPLE_PRODUCT: 'sample-product',
};

export const getPageContent = (mode) => {
    switch (mode) {
        case 'add':
            return {
                heading: 'Add Product',
                description: 'Easily input essential details like name, price, and more to showcase your product.',
            };
        case 'edit':
            return {
                heading: 'Edit Product',
                description: 'Update product details to keep your listings accurate and up-to-date.',
            };
        default:
            return {
                heading: 'All Products',
                description: 'Manage your products by adding new ones or editing existing product details.',
            };
    }
};