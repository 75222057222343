import { FiGlobe, FiAlertTriangle, FiUsers, FiShoppingCart, FiTruck } from 'react-icons/fi';
import APP_ROUTES from '../../../constant/appPath';

const settingsOptions = [
    {
        id: 'manage-store',
        icon: FiAlertTriangle,
        title: 'Manage Store',
        description: 'Manage store details such as name, logo, and other basic information.',
        route: APP_ROUTES.MANAGE_STORE_SETTINGS,
    },
    {
        id: 'delivery-options',
        icon: FiTruck,
        title: 'Delivery Options',
        description: 'Configure shipping methods, delivery zones, and rates.',
        route: APP_ROUTES.DELIVERY_OPTIONS_SETTINGS,
    },
    {
        id: 'payment-settings',
        icon: FiShoppingCart,
        title: 'Payment Settings',
        description: 'Set up payment methods and gateways for your store.',
        route: APP_ROUTES.PAYMENT_SETTINGS,
    },
    {
        id: 'seo-settings',
        icon: FiTruck,
        title: 'SEO Settings',
        description: 'Configure meta tags, social sharing, and other SEO options.',
        route: APP_ROUTES.SEO_SETTINGS,
    },
    {
        id: 'user-permissions',
        icon: FiUsers,
        title: 'User Permissions',
        description: 'Set permissions for team members or collaborators.',
        route: APP_ROUTES.USER_PERMISSIONS_SETTINGS,
    },
    {
        id: 'notification-settings',
        icon: FiUsers,
        title: 'Notification Settings',
        description: 'Manage how and where you receive notifications for store events.',
        route: APP_ROUTES.NOTIFICATION_SETTINGS,
    },
    {
        id: 'store-timings',
        icon: FiAlertTriangle,
        title: 'Store Timings',
        description: 'Define the days and hours your store will be open.',
        route: APP_ROUTES.STORE_TIMINGS_SETTINGS,
    },
    {
        id: 'domain-options',
        icon: FiGlobe,
        title: 'Domain Options',
        description: 'Configure your custom domain for the store.',
        route: APP_ROUTES.DOMAIN_OPTIONS_SETTINGS,
    },
    {
        id: 'support-details',
        icon: FiUsers,
        title: 'Support Details',
        description: 'Provide support contact details and social profile links.',
        route: APP_ROUTES.SUPPORT_DETAILS_SETTINGS,
    },
    {
        id: 'order-acceptance',
        icon: FiShoppingCart,
        title: 'Order Acceptance',
        description: 'Define how orders are accepted: automatically or manually.',
        route: APP_ROUTES.ORDER_ACCEPTANCE_SETTINGS,
    },
    {
        id: 'tax-settings',
        icon: FiAlertTriangle,
        title: 'Tax Settings',
        description: 'Configure GST, VAT, and other taxes for your store.',
        route: APP_ROUTES.TAX_SETTINGS,
    },
    {
        id: 'store-policies',
        icon: FiAlertTriangle,
        title: 'Store Policies',
        description: 'Define your return, refund, and shipping policies.',
        route: APP_ROUTES.STORE_POLICIES,
    },
    {
        id: 'integrations-settings',
        icon: FiUsers,
        title: 'Integrations Settings',
        description: 'Connect analytics and CRM tools to your store.',
        route: APP_ROUTES.INTEGRATIONS_SETTINGS,
    },
];

export default settingsOptions;