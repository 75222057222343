import { FiShoppingBag, FiUsers, FiStar, FiBarChart2, FiTruck, FiCreditCard, FiShare2, FiPercent, FiClipboard, FiBell } from 'react-icons/fi';

export const featuresList = [
  {
    id: 'ease-of-selling',
    title: 'Ease of Selling',
    color: 'green',
    icon: FiShoppingBag,
    content: 'Launch your online store in minutes! Effortlessly list products, manage inventory, and process order with ease.'
  },
  {
    id: 'know-customers',
    title: 'Know your Customers',
    color: 'yellow',
    icon: FiUsers,
    content: 'Build lasting customer relationships—manage profiles, track orders, and connect directly to enhance their experiences!'
  },
  {
    id: 'product-reviews',
    title: 'Product Reviews',
    color: 'green',
    icon: FiStar,
    content: 'Encourage Customers and Showcase reviews on product pages to build trust and boost sales!'
  },
  {
    id: 'detailed-reporting',
    title: 'Detailed Reporting',
    color: 'purple',
    icon: FiBarChart2,
    content: 'Gain insights, Track sales, analyze trends, and optimize your business with detailed reports!'
  },
  {
    id: 'delivery-options',
    title: 'Customized Delivery Options',
    color: 'blue',
    icon: FiTruck,
    content: 'Offer flexible delivery options—pickup, same-apartment, range-based, or nationwide! Manage delivery costs and time slots with ease.'
  },
  {
    id: 'payment',
    title: 'Online Payment Enablement',
    color: 'teal',
    icon: FiCreditCard,
    content: 'Enable secure payments with leading gateways for seamless transactions! Accept credit/debit cards, net banking, and more.'
  },
  {
    id: 'share',
    title: 'Share Button on Product Page',
    color: 'orange',
    icon: FiShare2,
    content: 'Expand your reach with easy share buttons—share products on WhatsApp, social media, and more!'
  },
  {
    id: 'gst',
    title: 'GST Tax Settings',
    color: 'red',
    icon: FiPercent,
    content: 'Manage taxes easily—toggle inclusive or exclusive GST pricing in your Store settings!'
  },
  {
    id: 'manual-orders',
    title: 'Manual Orders Management',
    color: 'purple',
    icon: FiClipboard,
    content: 'Create and manage orders, including external ones, with full control and visibility.'
  },
  {
    id: 'notifications',
    title: 'Instant Order Notifications',
    color: 'green',
    icon: FiBell,
    content: 'Get instant order alerts—accept, reject, or auto-manage with ease!'
  }
];