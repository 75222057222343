import React, {useState} from "react";
import { FormControl, FormLabel, Input, Textarea, VStack, HStack, Button } from "@chakra-ui/react";

const BrandingForm = ({ storeDetails, setStoreDetails }) => {

    const [formState, setFormState] = useState({
            logo: storeDetails.logo || '',
            tagline: storeDetails.tagline || '',
            shortDescription: storeDetails.store_description || '',
            longDescription: storeDetails.longDescription || '',
        });
    
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormState((prev) => ({
                ...prev,
                [name]: value,
            }));
        };
    
        const handleSave = () => {
            setStoreDetails(formState); // Call the parent handler to update store details
        };
    
        const handleCancel = () => {
            setFormState({
                logo: storeDetails.logo,
                tagline: storeDetails.tagline,
                shortDescription: storeDetails.store_description,
                longDescription: storeDetails.longDescription,
            });
        };

        const renderField = (name, label, placeholder) => (
            <FormControl>
                <FormLabel>{label}</FormLabel>
                <Input
                    name={name}
                    value={formState[name]}
                    onChange={handleChange}
                    placeholder={placeholder}
                />
            </FormControl>
        );
    
    return (
        <VStack align="stretch" spacing={4}>
            {renderField("logo", "Logo URL", "Paste your logo's image URL here")}
            {renderField("tagline", "Tagline", "Enter your store's tagline")}
            {renderField("shortDescription", "Short Description", "A brief description of your store")}
            {renderField("longDescription", "Long Description", "A detailed description of your store")}
            <HStack spacing={4} mt={8}>
                <Button
                    colorScheme="black"
                    bg="black"
                    color="white"
                    _hover={{ bg: 'gray.800' }}
                    onClick={handleSave}
                >
                    Save Changes
                </Button>
                <Button variant="ghost" onClick={handleCancel}>
                    Reset
                </Button>
            </HStack>
        </VStack>
    );
};

export default BrandingForm;