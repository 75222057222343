import { Box, Button, Input, Text, VStack } from '@chakra-ui/react';

const ConnectDomain = ({ onGoBack }) => {
  return (
    <VStack align="stretch" spacing={4}>
      {/* Title */}
      <Text fontWeight="bold" fontSize="lg">Connect an Existing Domain</Text>
      <Text fontSize="sm" color="gray.600">
        Link a domain you already own to your store. Enter your domain below to get specific instructions on how to connect it.
      </Text>

      {/* Domain Input */}
      <Input placeholder="Enter your domain (e.g., myshop.com)" />

      {/* Connection Instructions */}
      <Box mt={4} p={4} bg="gray.50" borderRadius="md" shadow="sm">
        <Text fontWeight="bold" mb={2}>Domain Setup Instructions</Text>
        <Text fontSize="sm" color="gray.600">
          Follow these steps with your domain provider (e.g., GoDaddy, Google Domains) to connect your domain:
        </Text>
        <VStack align="stretch" mt={2} spacing={2} p={2} bg="white" borderRadius="md">
          <Text>1. Log in to your domain provider's dashboard.</Text>
          <Text>2. Go to DNS settings and add the following records:</Text>
          <Box p={2} bg="gray.50" borderRadius="md">
            <Text><strong>A Record</strong> - x.y.z.q</Text>
            <Text><strong>CNAME</strong> - Set "www" to point to "shops.minitaka.com"</Text>
          </Box>
        </VStack>
        <Text fontSize="sm" color="gray.600" mt={2}>
          After updating the records, it may take up to 24 hours for the changes to propagate.
        </Text>
      </Box>

      {/* Go Back Button */}
      <Button size="sm" variant="link" onClick={onGoBack} mt={4}>
        Go back to Domain Options
      </Button>
    </VStack>
  );
};

export default ConnectDomain;