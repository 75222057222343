import React, { useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  NumberInput,
  NumberInputField,
  Select,
  Button,
  VStack,
  useToast,
} from '@chakra-ui/react';

const ManualOrderDrawer = ({ isOpen, onClose, onCreateOrder }) => {
  const toast = useToast();
  const [orderDetails, setOrderDetails] = useState({
    customerName: '',
    customerEmail: '',
    customerPhone: '',
    description: '',
    totalPrice: '',
    shippingAddress: '',
    status: 'new', // Default status
  });

  const handleSubmit = () => {
    if (!orderDetails.customerName || !orderDetails.totalPrice) {
      toast({
        title: 'Missing Information',
        description: 'Customer name and total amount are required.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    onCreateOrder(orderDetails);
    setOrderDetails({
      customerName: '',
      customerEmail: '',
      customerPhone: '',
      description: '',
      totalPrice: '',
      status: 'new',
    });
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create Manual Order</DrawerHeader>

        <DrawerBody>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Customer Name</FormLabel>
              <Input
                placeholder="Enter customer name"
                value={orderDetails.customerName}
                onChange={(e) =>
                  setOrderDetails({ ...orderDetails, customerName: e.target.value })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Customer Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter customer email"
                value={orderDetails.customerEmail}
                onChange={(e) =>
                  setOrderDetails({ ...orderDetails, customerEmail: e.target.value })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Customer Phone</FormLabel>
              <Input
                type="tel"
                placeholder="Enter customer phone"
                value={orderDetails.customerPhone}
                onChange={(e) =>
                  setOrderDetails({ ...orderDetails, customerPhone: e.target.value })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter order description"
                value={orderDetails.description}
                onChange={(e) =>
                  setOrderDetails({ ...orderDetails, description: e.target.value })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Total Price</FormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  placeholder="Enter total amount"
                  value={orderDetails.totalPrice}
                  onChange={(e) =>
                    setOrderDetails({ ...orderDetails, totalPrice: e.target.value })
                  }
                />
              </NumberInput>
            </FormControl>

            <FormControl>
            <FormLabel>Shipping Address</FormLabel>
            <Textarea
                placeholder="Enter shipping address"
                value={orderDetails.shippingAddress}
                onChange={(e) =>
                    setOrderDetails({ ...orderDetails, shippingAddress: e.target.value })
                }
            />
        </FormControl>

            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                value={orderDetails.status}
                onChange={(e) =>
                  setOrderDetails({ ...orderDetails, status: e.target.value })
                }
              >
                <option value="new">New</option>
                <option value="accepted">Accepted</option>
                <option value="rejected">Rejected</option>
              </Select>
            </FormControl>
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <Button colorScheme="blue" onClick={handleSubmit} mr={3}>
            Create Order
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ManualOrderDrawer;