import React, { useEffect, useState } from 'react';
import { Box, Button, Spinner, useToast, HStack, Heading, } from '@chakra-ui/react';
import { Plus } from 'lucide-react';
import EmptyState from '../../../components/EmptyState';
import OrdersTable from './OrdersTable';
import ManualOrderDrawer from './ManualOrderDrawer';
import orderImage from '../../../assets/empty-order.png'; // Replace with your image path
import apiHandler from '../../../services/apiHandler';
import { GET_ALL_ORDERS_BY_STORE, CREATE_ORDER } from '../../../services/apiConfig';

const OrdersPage = ({ storeId = 2 }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setDrawerOpen] = useState(false);


  const toast = useToast();

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await apiHandler({
        options: GET_ALL_ORDERS_BY_STORE,
        urlParams: { storeId },
      });
      setOrders(response.data);



    } catch (error) {
      console.error('Failed to fetch orders:', error);
      toast({
        title: 'Error loading orders',
        description: 'Failed to fetch orders. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrder = async (orderDetails) => {
    try {
      const response = await apiHandler({
        options: CREATE_ORDER,
        data: orderDetails,
      });

      setOrders((prevOrders = []) => [response.data, ...prevOrders]);
      toast({
        title: 'Order Created',
        description: 'The manual order has been successfully created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to create manual order:', error);
      toast({
        title: 'Error',
        description: 'Failed to create manual order. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const isEmptyObject = (variable) =>
    typeof variable === 'object' &&
    variable !== null &&
    !Array.isArray(variable) &&
    Object.keys(variable).length === 0;

  useEffect(() => {
    fetchOrders();
  }, [storeId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minH="200px">
        <Spinner size="lg" />
      </Box>
    );
  }

  return (
    <Box>
      {isEmptyObject(orders) || orders.length === 0 ? (
        <EmptyState
          imageSrc={orderImage}
          title="Orders will appear here"
          description="View and manage your orders here once received, or start by creating a manual order."
          buttonText="Create Manual Order"
          onButtonClick={() => setDrawerOpen(true)}
        />
      ) : (
        <>

          <HStack justify="space-between" mb={6}>
            <Heading size="lg">Orders</Heading>
            <Button
              leftIcon={<Plus size={20} />}
              colorScheme="black"
              bg="black"
              color="white"
              _hover={{ bg: 'gray.800' }}
              onClick={() => setDrawerOpen(true)}
            >
              Create Manual Order
            </Button>
          </HStack>
          <OrdersTable orders={orders} />
        </>
      )}

      <ManualOrderDrawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        onCreateOrder={handleCreateOrder}
      />
    </Box>
  );
};

export default OrdersPage;