import { Box, Container, Heading, Text, SimpleGrid, Button, VStack, Badge, HStack } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";

const positions = [
  {
    title: "Senior Frontend Engineer",
    department: "Engineering",
    location: "Bangalore",
    type: "Full-time",
    description: "Join our frontend team to build beautiful and performant user interfaces."
  },
  {
    title: "Product Manager",
    department: "Product",
    location: "Remote",
    type: "Full-time",
    description: "Drive product strategy and work closely with engineering teams."
  },
  {
    title: "Customer Success Manager",
    department: "Customer Success",
    location: "Mumbai",
    type: "Full-time",
    description: "Help our customers succeed and grow their businesses with MiniTaka."
  }
];

const Careers = () => (
  <Box py={16}>
    <Container maxW="7xl">
      <VStack spacing={16} align="stretch">
        {/* Hero Section */}
        <VStack spacing={6} textAlign="center">
          <Heading 
            size="2xl" 
            bgGradient="linear(to-r, teal.500, blue.500)" 
            bgClip="text"
          >
            Join Our Mission
          </Heading>
          <Text fontSize="xl" color="gray.600" maxW="3xl" mx="auto">
            Help us empower millions of small businesses across India. We're looking for
            passionate individuals who want to make a difference.
          </Text>
        </VStack>

        {/* Open Positions */}
        <Box>
          <Heading size="xl" mb={8}>Open Positions</Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
            {positions.map((position) => (
              <Box 
                key={position.title} 
                p={6} 
                bg="white" 
                rounded="lg" 
                shadow="md"
                borderWidth={1}
                borderColor="gray.100"
              >
                <VStack align="stretch" spacing={4}>
                  <Heading size="md">{position.title}</Heading>
                  <HStack spacing={2}>
                    <Badge colorScheme="teal">{position.department}</Badge>
                    <Badge colorScheme="blue">{position.location}</Badge>
                    <Badge colorScheme="purple">{position.type}</Badge>
                  </HStack>
                  <Text color="gray.600">{position.description}</Text>
                  <Button 
                    rightIcon={<FiArrowRight />} 
                    colorScheme="teal" 
                    variant="outline"
                  >
                    Apply Now
                  </Button>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </Box>

        {/* Benefits Section */}
        <Box>
          <Heading size="xl" mb={8}>Why Join Us?</Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
            <Box p={6} bg="white" rounded="lg" shadow="md">
              <VStack align="start" spacing={4}>
                <Heading size="md">Competitive Benefits</Heading>
                <Text color="gray.600">
                  • Health insurance for you and family
                  • Annual learning allowance
                  • Flexible work hours
                  • Remote work options
                  • Stock options
                </Text>
              </VStack>
            </Box>
            <Box p={6} bg="white" rounded="lg" shadow="md">
              <VStack align="start" spacing={4}>
                <Heading size="md">Growth & Impact</Heading>
                <Text color="gray.600">
                  • Fast-paced learning environment
                  • Mentorship opportunities
                  • Direct impact on small businesses
                  • Career advancement paths
                  • Regular team events
                </Text>
              </VStack>
            </Box>
          </SimpleGrid>
        </Box>
      </VStack>
    </Container>
  </Box>
);

export default Careers;