import { Box, Container, Heading, SimpleGrid, Text, VStack, Image } from "@chakra-ui/react";

const tutorials = [
  {
    title: "Getting Started",
    description: "Learn the basics of setting up your online store",
    image: "/assets/tutorials/getting-started.jpg"
  },
  {
    title: "Product Management",
    description: "Master product listing and inventory management",
    image: "/assets/tutorials/products.jpg"
  },
  {
    title: "Order Processing",
    description: "Handle orders efficiently and manage deliveries",
    image: "/assets/tutorials/orders.jpg"
  }
];

const Tutorials = () => (
  <Box py={16}>
    <Container maxW="7xl">
      <VStack spacing={8} align="stretch">
        <Heading>Tutorials & Guides</Heading>
        <Text color="gray.600">Learn how to make the most of your online store</Text>
        
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
          {tutorials.map((tutorial) => (
            <Box
              key={tutorial.title}
              borderRadius="lg"
              overflow="hidden"
              bg="white"
              shadow="md"
            >
              <Image
                src={tutorial.image}
                alt={tutorial.title}
                h="200px"
                w="full"
                objectFit="cover"
              />
              <Box p={6}>
                <Heading size="md" mb={2}>{tutorial.title}</Heading>
                <Text color="gray.600">{tutorial.description}</Text>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  </Box>
);

export default Tutorials;