import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Select, useDisclosure } from '@chakra-ui/react';
import axios from 'axios';

const CreateSampleProductModal = ({ isOpen, onClose, onProductCreated })  => {
  
  const [category, setCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateProduct = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('http://localhost/api/v1/products/sample', { userId: 1, category });
    //   onProductCreated(response.data.productId);
      console.log('Sample product created:', response.data.productId);
      onClose();
    } catch (error) {
      console.error("Failed to create sample product:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Category for Sample Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select placeholder="Select category" onChange={(e) => setCategory(e.target.value)}>
              <option value="electronics">Electronics</option>
              <option value="apparel">Apparel</option>
              {/* Add more categories as needed */}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" isLoading={isLoading} onClick={handleCreateProduct} isDisabled={!category}>
              Create Product
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateSampleProductModal;