import { HStack, Icon, Text } from '@chakra-ui/react';
import { BiCheck } from 'react-icons/bi';

const PricingFeature = ({ feature }) => (
  <HStack spacing={3} align="flex-start">
    <Icon 
      as={BiCheck} 
      h={5} 
      w={5} 
      color="green.500"
      transform="auto"
      translateY="2px"
    />
    <Text fontSize="sm" color="gray.600">
      {feature}
    </Text>
  </HStack>
);

export default PricingFeature;