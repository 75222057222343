export const shouldShowOnboarding = () => true;

export const renderPrice = (price, currency = "₹") => {
    // Check if the price is valid
    if (price === null || price === undefined || price === "" || isNaN(price)) {
        return `${currency} 0.00`; // Default to 0.00 if price is invalid
    }

    // Convert the price to a fixed decimal format
    const formattedPrice = parseFloat(price).toFixed(2);
    return `${currency} ${formattedPrice}`;
};

// Default (datetime) formatting
//console.log(formatDate(isoDate)); // Output: "Jan 2, 2025, 2:36 PM"
// Extract only the date
//console.log(formatDate(isoDate, "date")); // Output: "Jan 2, 2025"
// Extract only the time
//console.log(formatDate(isoDate, "time")); // Output: "2:36 PM"

export const formatDate = (isoDate, format = "datetime") => {
    // Check if the date is valid
    if (!isoDate) return "Invalid Date";

    try {
        // Create a Date object from the ISO string
        const date = new Date(isoDate);

        // Define default options for different formats
        const optionsMap = {
            datetime: {
                year: "numeric",
                month: "short", // Abbreviated month (e.g., Jan)
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            },
            date: {
                year: "numeric",
                month: "short",
                day: "numeric",
            },
            time: {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            },
        };

        // Get the formatting options for the selected format
        const options = optionsMap[format] || optionsMap["datetime"];

        // Format the date using Intl.DateTimeFormat
        return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
        console.error("Error formatting date:", error);
        return "Invalid Date";
    }
};