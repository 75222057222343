import { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Tabs,
  TabList,
  Tab,
  HStack,
  Button,
} from '@chakra-ui/react';
import { Calendar } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TabPanels, TabPanel } from '@chakra-ui/react';
import MetricCard from './MetricCard';
import SalesChart from './SalesChart';
import ProfitChart from './ProfitChart';
import CustomerChart from './CustomerChart';

export default function Reports() {
  const [metric, setMetric] = useState('Orders');
  const [timeGranularity, setTimeGranularity] = useState('Weekly');
  const [dateRange, setDateRange] = useState([
    new Date('2024-12-04'),
    new Date('2025-01-03'),
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasData, setHasData] = useState(true);

  const handleDateChange = (update) => {
    setDateRange(update);
    // Simulate API call
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setHasData(Math.random() > 0.5); // Randomly show empty state
    }, 1000);
  };

  const handleMetricChange = (newMetric) => {
    setMetric(newMetric);
    // Simulate API call
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setHasData(true);
    }, 1000);
  };

  const handleTimeGranularityChange = (granularity) => {
    setTimeGranularity(granularity);
    // Simulate API call
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setHasData(true);
    }, 1000);
  };

  return (
    <Box bg="gray.50" minH="100vh" py={8}>
      <Container maxW="container.xl">
        <HStack justify="space-between" mb={4}>
          <Box>
            <Heading size="lg">Reports</Heading>
            <Text color="gray.600">
              Analyze your store's performance with detailed reports
            </Text>
          </Box>
          <Button
            as={DatePicker}
            selectsRange
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            onChange={handleDateChange}
            dateFormat="MMM dd, yyyy"
            className="chakra-button"
            leftIcon={<Calendar size={20} />}
            variant="outline"
            size="md"
          >
            <Calendar size={20} />
            {dateRange[0]?.toLocaleDateString('en-US', { 
              month: 'short', 
              day: '2-digit',
              year: 'numeric'
            })} - {dateRange[1]?.toLocaleDateString('en-US', {
              month: 'short',
              day: '2-digit',
              year: 'numeric'
            })}
          </Button>
        </HStack>

        <Tabs colorScheme="black">
          <TabList>
            <Tab>Sales</Tab>
            <Tab>Profit</Tab>
            <Tab>Customers</Tab>
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              <HStack spacing={4} mb={6}>
                <MetricCard
                  title="Total Orders"
                  value="640"
                  change="12.5%"
                  isPositive={true}
                />
                <MetricCard
                  title="Total Sales"
                  value="$27,800"
                  change="15.3%"
                  isPositive={true}
                />
                <MetricCard
                  title="Average Order Value"
                  value="$43.44"
                  change="2.4%"
                  isPositive={true}
                />
                <MetricCard
                  title="Conversion Rate"
                  value="3.2%"
                  change="0.3%"
                  isPositive={true}
                />
              </HStack>

              <SalesChart
                metric={metric}
                onMetricChange={handleMetricChange}
                timeGranularity={timeGranularity}
                onTimeGranularityChange={handleTimeGranularityChange}
                isLoading={isLoading}
                hasData={hasData}
              />
            </TabPanel>

            <TabPanel px={0}>
              <HStack spacing={4} mb={6}>
                <MetricCard
                  title="Total Revenue"
                  value="$16,060"
                  change="20.1%"
                  isPositive={true}
                />
                <MetricCard
                  title="Total Costs"
                  value="$26,106"
                  change="15.5%"
                  isPositive={false}
                />
                <MetricCard
                  title="Net Profit"
                  value="-$10,046"
                  change="8.4%"
                  isPositive={false}
                />
              </HStack>

              <ProfitChart isLoading={isLoading} hasData={hasData} />
            </TabPanel>

            <TabPanel px={0}>
              <HStack spacing={4} mb={6}>
                <MetricCard
                  title="Total Customers"
                  value="2,834"
                  change="18.2%"
                  isPositive={true}
                />
                <MetricCard
                  title="New Customers"
                  value="482"
                  change="4.3%"
                  isPositive={true}
                />
                <MetricCard
                  title="Customer Lifetime Value"
                  value="$234"
                  change="12.3%"
                  isPositive={true}
                />
                <MetricCard
                  title="Retention Rate"
                  value="68%"
                  change="3.1%"
                  isPositive={true}
                />
              </HStack>

              <CustomerChart isLoading={isLoading} hasData={hasData} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Box>
  );
}