// StorePage.js
import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

import Template1 from './templates/shop/Template1';
import apiHandler from "../services/apiHandler";
import { FETCH_STORE_DETAILS } from "../services/apiConfig";
// import other templates as needed

const templates = {
  Template1: Template1,
  // Template2: Template2,
  // Add more templates here
};

const OnlineStore = () => {
  const { store_name } = useParams();
  const [storeData, setStoreData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
        // Fetch store details from the backend using store_name
        const fetchStoreData = async () => {

            try {
                const response = await apiHandler({
                    options: FETCH_STORE_DETAILS,
                    urlParams: { store_name: store_name }
                });

                console.log(response);
                setStoreData(response);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchStoreData();
    }, [store_name]);

  if (!storeData) return <div>Loading...</div>;

  const SelectedTemplate = templates[storeData.template] || templates.Template1; // Default to Template1 if none set

  return <SelectedTemplate shopData={storeData} />;
  return (
    <Router>
      <SelectedTemplate shopData={storeData} />
      <Routes>
        <Route path="/" element={<SelectedTemplate shopData={storeData} />} />
        {/* <Route path="/products" element={<ProductsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default OnlineStore;