import { Box, Text, HStack, Button, Select, Flex } from '@chakra-ui/react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import EmptyState from './EmptyState';

const data = [
  { week: 'Week 1', orders: 120 },
  { week: 'Week 2', orders: 150 },
  { week: 'Week 3', orders: 180 },
  { week: 'Week 4', orders: 200 },
];


export default function SalesChart({
  metric,
  onMetricChange,
  timeGranularity,
  onTimeGranularityChange,
  isLoading = false,
  hasData = true,
}) {
  return (
    <Box bg="white" p={6} borderRadius="lg" shadow="sm">
      <Flex justify="space-between" align="center" mb={6}>
        <Box>
          <Text fontSize="lg" fontWeight="semibold">Sales Overview</Text>
          <Text fontSize="sm" color="gray.600">
            View your store's sales performance over time
          </Text>
        </Box>
        <HStack spacing={4}>
          <HStack>
            <Button
              size="sm"
              variant={metric === 'Orders' ? 'solid' : 'outline'}
              colorScheme="black"
              bg={metric === 'Orders' ? 'black' : 'transparent'}
              color={metric === 'Orders' ? 'white' : 'black'}
              onClick={() => onMetricChange('Orders')}
            >
              Orders
            </Button>
            <Button
              size="sm"
              variant={metric === 'Amount' ? 'solid' : 'outline'}
              colorScheme="black"
              bg={metric === 'Amount' ? 'black' : 'transparent'}
              color={metric === 'Amount' ? 'white' : 'black'}
              onClick={() => onMetricChange('Amount')}
            >
              Amount
            </Button>
          </HStack>

          <Select
            size="sm"
            w="120px"
            value={timeGranularity}
            onChange={(e) => onTimeGranularityChange(e.target.value)}
          >
            <option value="Daily">Daily</option>
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
          </Select>
        </HStack>
      </Flex>

      <Box h="400px">
        {isLoading ? (
          <Flex h="full" align="center" justify="center">
            <Text>Loading...</Text>
          </Flex>
        ) : !hasData ? (
          <EmptyState />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="week" />
              <YAxis />
              <Bar dataKey="orders" fill="#2196F3" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Box>
  );
}