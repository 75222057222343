import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Switch,
  Button,
  useToast,
} from '@chakra-ui/react';
import BackToSettings from '../BackToSettings';

const NotificationSettings = () => {
  const [settings, setSettings] = useState({
    email: true,
    sms: false,
    push: true,
  });
  const toast = useToast();

  const handleSave = () => {
    toast({
      title: 'Notification Settings Saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('Notification Settings:', settings);
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={6}>Notification Settings</Heading>
      <VStack spacing={4} align="stretch">
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable Email Notifications</FormLabel>
          <Switch
            isChecked={settings.email}
            onChange={(e) =>
              setSettings({ ...settings, email: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable SMS Notifications</FormLabel>
          <Switch
            isChecked={settings.sms}
            onChange={(e) =>
              setSettings({ ...settings, sms: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable Push Notifications</FormLabel>
          <Switch
            isChecked={settings.push}
            onChange={(e) =>
              setSettings({ ...settings, push: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        <Button colorScheme="teal" onClick={handleSave}>
          Save Notification Settings
        </Button>
      </VStack>
    </Box>
  );
};

export default NotificationSettings;