import React, { useEffect, useState } from 'react';
import {
  Box, Heading, Text, HStack, VStack, Button, Image, IconButton, Stack, Divider, Grid, GridItem, Input, RadioGroup, Radio, SimpleGrid
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiPlus, FiMinus, FiTrash } from 'react-icons/fi';
import HeaderWithSubnavigation from './Header';

// Helper function to load cart from localStorage
const loadCartFromLocalStorage = () => {
  const savedCart = localStorage.getItem('cart');
  return savedCart ? JSON.parse(savedCart) : [];
};

const CheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cart, setCart] = useState(location.state?.cart || loadCartFromLocalStorage());  // Load from navigation state or localStorage

  // Handle quantity update in the checkout page
  const handleUpdateQuantity = (productId, change) => {
    const updatedCart = cart.map(item =>
      item.id === productId
        ? { ...item, quantity: Math.max(0, item.quantity + change) }
        : item
    );
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));  // Persist cart to localStorage
  };

  // Handle item removal from cart
  const handleRemoveFromCart = (productId) => {
    const updatedCart = cart.filter(item => item.id !== productId);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));  // Persist cart to localStorage
  };

  // Redirect to the homepage or cart page if the cart is empty
  useEffect(() => {
    if (cart.length === 0) {
      navigate('/');  // Redirect to the home or product page if the cart is empty
    }
  }, [cart, navigate]);

  return (
    <Box>
      {/* Shared Header */}
      <HeaderWithSubnavigation cartItemCount={cart.length} />

      {/* Checkout Page Content */}
      {cart.length > 0 ? (
        <Box maxW="7xl" mx="auto" py={10} px={4}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            {/* Left Section - Shipping Information */}
            <Box>
              <Heading fontSize="2xl" mb={6}>Shipping Information</Heading>
              <Stack spacing={4}>
                <Input placeholder="Your first and last name" />
                <Input placeholder="123 Example Street" />
                <HStack>
                  <Input placeholder="Zip Code" />
                  <Input placeholder="City" />
                </HStack>
                <Input placeholder="you@example.com" />
              </Stack>

              <Divider my={6} />

              {/* Payment Information */}
              <Heading fontSize="2xl" mb={4}>Payment Information</Heading>
              <RadioGroup defaultValue="creditCard">
                <HStack spacing={6}>
                  <Radio value="creditCard">Credit Card</Radio>
                  <Radio value="paypal">PayPal</Radio>
                </HStack>
              </RadioGroup>
              <Stack mt={4}>
                <Input placeholder="Card number" />
                <Input placeholder="Name on card" />
                <HStack>
                  <Input placeholder="MM/YY" />
                  <Input placeholder="CVC" />
                </HStack>
              </Stack>
            </Box>

            {/* Right Section - Order Summary */}
            <Box>
              <Heading fontSize="2xl" mb={6}>Order Summary</Heading>
              <Stack spacing={4}>
                {cart.map((item, index) => (
                  <HStack key={index} spacing={4} align="flex-start">
                    <Image
                      boxSize="100px"
                      objectFit="cover"
                      src={item.image || 'https://via.placeholder.com/150'}
                      alt={item.name}
                    />
                    <VStack align="start" spacing={1} flex="1">
                      <HStack justify="space-between" width="full">
                        <Text fontWeight="bold">{item.name}</Text>
                        <Text>₹{item.price}</Text>
                      </HStack>
                      <HStack>
                        <IconButton
                          icon={<FiMinus />}
                          onClick={() => handleUpdateQuantity(item.id, -1)}
                          isDisabled={item.quantity === 0}
                          aria-label="Decrease quantity"
                        />
                        <Text>{item.quantity}</Text>
                        <IconButton
                          icon={<FiPlus />}
                          onClick={() => handleUpdateQuantity(item.id, 1)}
                          aria-label="Increase quantity"
                        />
                      </HStack>
                      <IconButton
                        icon={<FiTrash />}
                        onClick={() => handleRemoveFromCart(item.id)}
                        aria-label="Remove from cart"
                        colorScheme="red"
                      />
                    </VStack>
                  </HStack>
                ))}

                <Divider />

                {/* Pricing Summary */}
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <GridItem>
                    <Text>Subtotal</Text>
                  </GridItem>
                  <GridItem textAlign="right">
                    <Text>₹{cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}</Text>
                  </GridItem>

                  <GridItem>
                    <Text>Shipping cost</Text>
                  </GridItem>
                  <GridItem textAlign="right">
                    <Text>₹24.99</Text>
                  </GridItem>

                  <GridItem fontWeight="bold">
                    <Text>Order Total</Text>
                  </GridItem>
                  <GridItem textAlign="right" fontWeight="bold">
                    <Text>₹{(cart.reduce((total, item) => total + item.price * item.quantity, 0) + 24.99).toFixed(2)}</Text>
                  </GridItem>
                </Grid>

                <Divider />

                {/* Place Order Button */}
                <Button colorScheme="blue" size="lg" w="full">
                  Place Order
                </Button>

                {/* Support Options */}
                <Text fontSize="sm" mt={2}>
                  Have questions or need help to complete your order?
                </Text>
                <HStack>
                  <Button variant="link">Live Chat</Button>
                  <Button variant="link">Phone</Button>
                  <Button variant="link">Email</Button>
                </HStack>
              </Stack>
            </Box>
          </SimpleGrid>
        </Box>
      ) : (
        <Box textAlign="center" py={10}>
          <Heading size="lg">Your cart is empty</Heading>
          <Button mt={4} colorScheme="blue" onClick={() => navigate('/')}>
            Continue Shopping
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CheckoutPage;
