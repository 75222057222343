import React, { useState, useEffect } from "react";
import { Box, Heading, Input, IconButton, List, ListItem } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import apiHandler from "../services/apiHandler";
import { AUTOCOMPLETE_ADDRESS } from "../services/apiConfig";

const RenderCityInput = ({ city, setCity, isCitySelected, setIsCitySelected }) => {
    const [citySuggestions, setCitySuggestions] = useState([]);

    useEffect(() => {
        if (isCitySelected) return;

        const delayDebounceFn = setTimeout(async () => {
            if (city.length > 2) {
                try {
                    const response = await apiHandler({
                        options: AUTOCOMPLETE_ADDRESS,
                        params: { input: city, types: "(cities)", country: "IN" }
                    });
                    console.log("City suggestions:", response.data, isCitySelected);
                    // Verify if response.data is an array
                    const suggestionsArray = Object.values(response.data);
                    setCitySuggestions(suggestionsArray );
                    
                } catch (error) {
                    console.error("Error fetching city suggestions:", error);
                    setCitySuggestions([]);
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [city, isCitySelected]);

    const handleCityChange = (event) => {
        setCity(event.target.value);
        setIsCitySelected(false); // Reset selection state if user is typing
    };

    const handleSelectCity = (selectedCity) => {
        setCity(selectedCity);
        setIsCitySelected(true);
        setCitySuggestions([]);
    };

    const clearCitySelection = () => {
        setCity("");
        setIsCitySelected(false);
        setCitySuggestions([]);
    };

    return (
        <Box textAlign="left" mb={6} position="relative">
            <Heading size="md" mb={2}>City</Heading>
            <Box display="flex" alignItems="center">
                <Input
                    value={city}
                    onChange={handleCityChange}
                    placeholder="Enter your city (India only)"
                    autoComplete="off"
                    pr={city ? "2.5rem" : "0.5rem"}
                />
                {city && (
                    <IconButton
                        aria-label="Clear city selection"
                        icon={<CloseIcon />}
                        size="sm"
                        position="absolute"
                        right="10px"
                        onClick={(e) => {
                            e.stopPropagation();
                            clearCitySelection();
                        }}
                    />
                )}
            </Box>
            {citySuggestions.length > 0 && !isCitySelected && (
                <List
                    mt={2}
                    borderWidth="1px"
                    borderRadius="md"
                    bg="white"
                    maxH="200px"
                    overflowY="auto"
                    boxShadow="md"
                    zIndex="10"
                    position="absolute"
                    width="100%"
                >
                    {citySuggestions.map((suggestion, index) => (
                        <ListItem
                            key={index}
                            p={2}
                            cursor="pointer"
                            _hover={{ bg: "gray.100" }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => handleSelectCity(suggestion.description)}
                        >
                            {suggestion.description}
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default RenderCityInput;