// components/FeatureSection.js
import React from "react";
import { Stack } from "@chakra-ui/react";
import { Flex, Icon } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const Feature = ({ children }) => (
  <Flex alignItems="center">
    <Icon as={CheckCircleIcon} boxSize={4} mr={1} color="green.600" />
    {children}
  </Flex>
);

const FeatureSection = () => (
  <Stack
    display="flex"
    direction={{ base: "column", md: "row" }}
    justifyContent={{ base: "start", md: "center" }}
    mb={3}
    spacing={{ base: 2, md: 8 }}
    fontSize="xs"
    color="gray.600"
  >
    <Feature>No upfront payment required</Feature>
    <Feature>30 days free</Feature>
    <Feature>Cancel anytime</Feature>
  </Stack>
);

export default FeatureSection;
