import React from 'react';
import { FormControl, FormLabel, Input, Select, Textarea } from '@chakra-ui/react';

const StoreSettingsForm = ({ storeDetails, setStoreDetails }) => {
    return (
        <>
            <FormControl mb={4}>
                <FormLabel>Currency</FormLabel>
                <Select
                    defaultValue={storeDetails.currency}
                    onChange={(e) =>
                        setStoreDetails((prev) => ({ ...prev, currency: e.target.value }))
                    }
                >
                    <option value="USD">USD</option>
                    <option value="INR">INR</option>
                    <option value="EUR">EUR</option>
                </Select>
            </FormControl>
            <FormControl mb={4}>
                <FormLabel>Weight Unit</FormLabel>
                <Select
                    defaultValue={storeDetails.weightUnit}
                    onChange={(e) =>
                        setStoreDetails((prev) => ({ ...prev, weightUnit: e.target.value }))
                    }
                >
                    <option value="kg">Kilogram (kg)</option>
                    <option value="lb">Pound (lb)</option>
                </Select>
            </FormControl>
            <FormControl mb={4}>
                <FormLabel>Timezone</FormLabel>
                <Input
                    defaultValue={storeDetails.timezone}
                    onChange={(e) =>
                        setStoreDetails((prev) => ({ ...prev, timezone: e.target.value }))
                    }
                />
            </FormControl>
            <FormControl mb={4}>
                <FormLabel>Order ID Format</FormLabel>
                <Input
                    defaultValue={storeDetails.orderIDFormat}
                    onChange={(e) =>
                        setStoreDetails((prev) => ({
                            ...prev,
                            orderIDFormat: e.target.value,
                        }))
                    }
                    placeholder="Enter the format for Order IDs"
                />
            </FormControl>
            <FormControl mb={4}>
                <FormLabel>Order Processing</FormLabel>
                <Textarea
                    defaultValue={storeDetails.orderProcessing}
                    onChange={(e) =>
                        setStoreDetails((prev) => ({
                            ...prev,
                            orderProcessing: e.target.value,
                        }))
                    }
                    placeholder="Describe your order processing preference"
                />
            </FormControl>
        </>
    );
};

export default StoreSettingsForm;