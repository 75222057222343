import { HStack, IconButton, Link } from "@chakra-ui/react";
import { FiGithub, FiTwitter, FiLinkedin, FiInstagram } from "react-icons/fi";

const socialLinks = [
  { icon: FiTwitter, href: "https://twitter.com", label: "Twitter" },
  { icon: FiLinkedin, href: "https://www.linkedin.com/company/minitaka", label: "LinkedIn" },
  // { icon: FiGithub, href: "https://github.com", label: "GitHub" },
  { icon: FiInstagram, href: "https://instagram.com/minitaka.online", label: "Instagram" }
];

export const SocialLinks = () => (
  <HStack spacing={4}>
    {socialLinks.map((social) => (
      <IconButton
        key={social.label}
        as={Link}
        href={social.href}
        aria-label={social.label}
        icon={<social.icon />}
        variant="ghost"
        color="gray.500"
        _hover={{
          color: "teal.500",
          transform: "translateY(-2px)"
        }}
        transition="all 0.2s"
        isExternal
      />
    ))}
  </HStack>
);