import React from "react";
import { Button } from "@chakra-ui/react";
import { Plus } from 'lucide-react';

export default function PrimaryActionButton({ children, onClickAction, ...props }) {
    return (
        <Button
            {...props}
            leftIcon={<Plus size="16px" color='red'/>} 
            onClick={onClickAction}
            colorScheme="black"
            bg="black"
            color="white"
            _hover={{ bg: 'gray.800' }}
        >
            {children}
        </Button>
    )
};