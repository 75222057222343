import { Box, Text, VStack, Icon } from '@chakra-ui/react';
import { LineChart } from 'lucide-react';

export default function EmptyState() {
  return (
    <VStack spacing={4} py={16}>
      <Icon as={LineChart} boxSize={12} color="gray.300" />
      <Box textAlign="center">
        <Text fontSize="lg" fontWeight="medium" color="gray.700">
          No data available
        </Text>
        <Text color="gray.500">
          There is no data available for the selected time period.
        </Text>
      </Box>
    </VStack>
  );
}