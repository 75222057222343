import { Box, Text, Flex } from '@chakra-ui/react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend } from 'recharts';
import EmptyState from './EmptyState';

const profitData = [
  { month: 'Jan', revenue: 3000, costs: 6000, profit: -3000 },
  { month: 'Feb', revenue: 2000, costs: 4000, profit: -2000 },
  { month: 'Mar', revenue: 1000, costs: 8000, profit: -7000 },
  { month: 'Apr', revenue: 2700, costs: 5000, profit: -2300 },
  { month: 'May', revenue: 2500, costs: 4500, profit: -2000 },
  { month: 'Jun', revenue: 2800, costs: 4800, profit: -2000 },
];

export default function ProfitChart({ isLoading = false, hasData = true }) {
  return (
    <Box bg="white" p={6} borderRadius="lg" shadow="sm">
      <Text fontSize="lg" fontWeight="semibold">Profit Analysis</Text>
      <Text fontSize="sm" color="gray.600" mb={6}>
        View your revenue, costs, and profit over time
      </Text>

      <Box h="400px">
        {isLoading ? (
          <Flex h="full" align="center" justify="center">
            <Text>Loading...</Text>
          </Flex>
        ) : !hasData ? (
          <EmptyState />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={profitData}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="month" />
              <YAxis />
              <Legend />
              <Area
                type="monotone"
                dataKey="revenue"
                stackId="1"
                stroke="#4CAF50"
                fill="#4CAF50"
                name="Revenue"
              />
              <Area
                type="monotone"
                dataKey="costs"
                stackId="1"
                stroke="#FF5252"
                fill="#FF5252"
                name="Costs"
              />
              <Area
                type="monotone"
                dataKey="profit"
                stackId="2"
                stroke="#2196F3"
                fill="#2196F3"
                name="Profit"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Box>
  );
}