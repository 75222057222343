// DashboardPage.js
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import GettingStarted from './GettingStarted';
import { shouldShowOnboarding } from '../../helpers/utils';

const DashboardPage = () => {
    return (
        <Flex direction="column" alignItems="center" justifyContent="center" width="100%">
                <Box w="90%" p={5} mt={0} flex="1 0 90%" bg="white">
                    {shouldShowOnboarding() && <GettingStarted />}
                </Box>
            </Flex>
    );
};

export default DashboardPage;