import React, { useState } from 'react';
import { Box, Flex, InputGroup, InputLeftElement, Input, IconButton } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { FiLogOut } from 'react-icons/fi';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import axios from 'axios';
import SidebarWithHeader from '../components/Sidebar/Sidebar';

const LayoutSellerPortal = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleLogout = async () => {
        try {
            await axios.post('http://localhost/auth/session/signout', {}, { withCredentials: true });
            window.location.href = '/login';
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    return (
        // <Flex minH="100vh" direction="row" bg="gray.50">
        //     <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        //     <Flex flex="1" direction="column">
        //         {/* Search Section */}
        //         <Box
        //             bg="white"
        //             p={2}
        //             borderWidth="1px"
        //             borderLeftWidth={0}
        //             display="flex"
        //             justifyContent="space-between"
        //             alignItems="center"
        //             mb={4}  // Margin below search section
        //         >
        //             <InputGroup>
        //                 <InputLeftElement pointerEvents="none">
        //                     <SearchIcon color="gray.300" />
        //                 </InputLeftElement>
        //                 <Input type="text" placeholder="Search anything..." borderWidth={0} />
        //             </InputGroup>

        //             <IconButton
        //                 aria-label="Logout"
        //                 icon={<FiLogOut />}
        //                 onClick={handleLogout}
        //                 variant="ghost"
        //                 colorScheme="red"
        //                 ml={4}
        //             />
        //         </Box>

        //         {/* Dynamic Content */}
        //         <Box flex="1" p={4}>
        //             <Flex direction="column" alignItems="center" justifyContent="center" width="100%">
        //                 <Box w="90%" p={5} mt={0} flex="1 0 90%" bg="white">
        //                     <Outlet /> {/* Render nested routes here */}
        //                 </Box>
        //             </Flex>
        //         </Box>
        //     </Flex>
        // </Flex>
        <Box minH="100vh">
            <SidebarWithHeader />
            <Box ml={{ base: 0, md: 60 }} p="4">
                <Flex direction="column" alignItems="center" justifyContent="center" width="100%">
                    <Box w="90%" p={5} mt={0} flex="1 0 90%" bg="white">
                        <Outlet />
                        {/* Content */}
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default LayoutSellerPortal;