import { Box, Icon, Text, VStack, Button, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import PricingFeature from './PricingFeature';

const MotionBox = motion(Box);

const PricingCard = ({ 
  title, 
  price, 
  icon, 
  features, 
  description, 
  comingSoon, 
  isPopular ,
  billingPeriod
}) => {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const comingSoonColor = useColorModeValue('gray.50', 'gray.800');

  return (
    <MotionBox
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minW={{ base: 'xs', sm: 'xs', lg: 'sm' }}
      rounded="xl"
      bg={comingSoon ? comingSoonColor : bgColor}
      border="1px solid"
      borderColor={isPopular ? 'teal.500' : borderColor}
      boxShadow={isPopular ? 'xl' : 'md'}
      marginInline="auto"
      my={3}
      p={6}
      h={700}
    >
      {isPopular && (
        <Box
          position="absolute"
          top="-4"
          right="-4"
          bg="teal.500"
          color="white"
          fontSize="sm"
          fontWeight="semibold"
          px={3}
          py={1}
          rounded="full"
        >
          Popular
        </Box>
      )}
      
      <VStack spacing={4} align="center">
        <Icon as={icon} h={12} w={12} color="teal.500" />
        <Text fontSize="2xl" fontWeight="bold">
          {title}
        </Text>
        <Text fontSize="sm" color="gray.500" textAlign="center">
          {description}
        </Text>
        
        <Box textAlign="center">
          <Text as="span" fontSize="3xl" fontWeight="normal">
            ₹
          </Text>
          <Text 
            as="span" 
            fontSize="6xl" 
            fontWeight="bold" 
            marginLeft={1}
          >
            {price}
          </Text>
          <Text fontSize="md" color="gray.500">
            {`per ${billingPeriod}`}
          </Text>
        </Box>
      </VStack>

      <VStack spacing={4} align="stretch" my={8}>
        {features.map((feature, index) => (
          <PricingFeature key={index} feature={feature} />
        ))}
      </VStack>

      {/* <Button
        colorScheme={comingSoon ? "blue" : "teal"}
        variant={isPopular ? "solid" : "outline"}
        size="lg"
        rounded="lg"
        w="100%"
        _hover={{
          transform: 'scale(1.02)',
          transition: 'all 0.2s ease-in-out'
        }}
      >
        {comingSoon ? 'Know more' : 'Get Started'}
      </Button> */}
    </MotionBox>
  );
};

export default PricingCard;