import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Switch,
  useToast,
} from '@chakra-ui/react';
import BackToSettings from '../BackToSettings';

const IntegrationsSettings = () => {
  const [integrations, setIntegrations] = useState({
    googleAnalytics: '',
    facebookPixel: '',
    crmEnabled: false,
    crmApiKey: '',
  });
  const toast = useToast();

  const handleSave = () => {
    toast({
      title: 'Integrations Saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('Integrations:', integrations);
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={6}>Integrations Settings</Heading>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Google Analytics ID</FormLabel>
          <Input
            placeholder="Enter your Google Analytics ID"
            value={integrations.googleAnalytics}
            onChange={(e) =>
              setIntegrations({ ...integrations, googleAnalytics: e.target.value })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Facebook Pixel ID</FormLabel>
          <Input
            placeholder="Enter your Facebook Pixel ID"
            value={integrations.facebookPixel}
            onChange={(e) =>
              setIntegrations({ ...integrations, facebookPixel: e.target.value })
            }
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable CRM Integration</FormLabel>
          <Switch
            isChecked={integrations.crmEnabled}
            onChange={(e) =>
              setIntegrations({ ...integrations, crmEnabled: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        {integrations.crmEnabled && (
          <FormControl>
            <FormLabel>CRM API Key</FormLabel>
            <Input
              placeholder="Enter your CRM API key"
              value={integrations.crmApiKey}
              onChange={(e) =>
                setIntegrations({ ...integrations, crmApiKey: e.target.value })
              }
            />
          </FormControl>
        )}
        <Button colorScheme="teal" onClick={handleSave}>
          Save Integrations
        </Button>
      </VStack>
    </Box>
  );
};

export default IntegrationsSettings;