import { Box, Container, Input, SimpleGrid, Heading, Text, VStack } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

const categories = [
  {
    title: "Getting Started",
    articles: ["Account Setup", "Store Configuration", "Adding Products"]
  },
  {
    title: "Orders & Shipping",
    articles: ["Order Management", "Shipping Settings", "Returns"]
  },
  {
    title: "Payments",
    articles: ["Payment Methods", "Refunds", "Invoicing"]
  }
];

const HelpCenter = () => (
  <Box py={16}>
    <Container maxW="7xl">
      <VStack spacing={12} align="stretch">
        <VStack spacing={4} align="center">
          <Heading>How can we help?</Heading>
          <Box maxW="2xl" w="full">
            <Input
              size="lg"
              placeholder="Search for help..."
              leftElement={<FiSearch />}
            />
          </Box>
        </VStack>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
          {categories.map((category) => (
            <Box key={category.title} p={6} bg="white" rounded="lg" shadow="md">
              <Heading size="md" mb={4}>{category.title}</Heading>
              <VStack align="stretch" spacing={2}>
                {category.articles.map((article) => (
                  <Text
                    key={article}
                    color="teal.600"
                    cursor="pointer"
                    _hover={{ color: "teal.500" }}
                  >
                    {article}
                  </Text>
                ))}
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  </Box>
);

export default HelpCenter;