import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    VStack,
    HStack,
} from '@chakra-ui/react';

const StoreDetailsForm = ({ storeDetails, setStoreDetails }) => {
    const [formState, setFormState] = useState({
        storeName: storeDetails.store_name || '',
        storeURL: storeDetails.storeURL || '',
        billingAddress: storeDetails.billingAddress || '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = () => {
        setStoreDetails(formState); // Call the parent handler to update store details
    };

    const handleCancel = () => {
        setFormState({
            storeName: storeDetails.store_name,
            storeURL: storeDetails.storeURL,
            billingAddress: storeDetails.billingAddress,
        });
    };

    return (
        <VStack align="stretch" spacing={4}>
            <FormControl>
                <FormLabel>Store Name</FormLabel>
                <Input
                    name="storeName"
                    value={formState.storeName}
                    onChange={handleChange}
                    placeholder="Enter your store name"
                />
            </FormControl>

            <FormControl>
                <FormLabel>Store URL</FormLabel>
                <Input
                    name="storeURL"
                    value={formState.storeURL}
                    onChange={handleChange}
                    placeholder="https://mystore.com"
                />
            </FormControl>

            <FormControl>
                <FormLabel>Billing Address</FormLabel>
                <Textarea
                    name="billingAddress"
                    value={formState.billingAddress}
                    onChange={handleChange}
                    placeholder="Enter your billing address"
                />
            </FormControl>


            <HStack spacing={4} mt={8}>
                <Button
                    colorScheme="black"
                    bg="black"
                    color="white"
                    _hover={{ bg: 'gray.800' }}
                    onClick={handleSave}
                >
                    Save Changes
                </Button>
                <Button variant="ghost" onClick={handleCancel}>
                    Reset
                </Button>
            </HStack>
        </VStack>
    );
};

export default StoreDetailsForm;