import { Box, Container, Heading, Text, VStack, UnorderedList, ListItem } from "@chakra-ui/react";

const PrivacyPolicy = () => (
  <Box py={16}>
    <Container maxW="4xl">
      <VStack spacing={8} align="stretch">
        <Heading>Privacy Policy</Heading>
        <Text color="gray.600">Last updated: {new Date().toLocaleDateString()}</Text>
        
        <VStack spacing={6} align="stretch">
          <Box>
            <Heading size="md" mb={4}>1. Information We Collect</Heading>
            <UnorderedList spacing={2}>
              <ListItem>Account information</ListItem>
              <ListItem>Usage data</ListItem>
              <ListItem>Device information</ListItem>
            </UnorderedList>
          </Box>

          <Box>
            <Heading size="md" mb={4}>2. How We Use Your Information</Heading>
            <Text color="gray.600">
              We use the information we collect to provide, maintain, and improve our services.
            </Text>
          </Box>
        </VStack>
      </VStack>
    </Container>
  </Box>
);

export default PrivacyPolicy;