// routes/SellerRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";

import LayoutSellerHome from "../Layout/SellerHome";

import SellerHome from '../pages/SellerHome';

import Onboarding from "../pages/SellerHome/Onboarding";
import SignInPage from "../pages/Signin";

import About from "../pages/OtherPages/About";
import Careers from "../pages/OtherPages/Careers";
import Press from "../pages/OtherPages/Press";
import Contact from "../pages/OtherPages/Contact";
import HelpCenter from "../pages/OtherPages/HelpCenter";
import Tutorials from "../pages/OtherPages/Tutorials";

import PrivacyPolicy from "../pages/OtherPages/PrivacyPolicy";
import TermsAndConditions from "../pages/OtherPages/TermsAndConditions";

const WebsiteRoutes = () => (
    <Routes>
        <Route path="/" element={<LayoutSellerHome />}>
            <Route index element={<SellerHome />} />
            <Route path="/onboarding" element={<Onboarding />} />

            <Route path="/login?show=signup" element={<SignInPage />} />
            <Route path="/login" element={<SignInPage />} />

            {/* Other Static Pages */}
            <Route path="/company/about" element={<About />} />
            <Route path="/company/careers" element={<Careers />} />
            <Route path="/company/press" element={<Press />} />
            <Route path="/company/contact" element={<Contact />} />
            <Route path="/resources/help" element={<HelpCenter />} />
            <Route path="/resources/tutorials" element={<Tutorials />} />

            <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/legal/terms-and-conditions" element={<TermsAndConditions />} />
        </Route>
    </Routes>
);

export default WebsiteRoutes;