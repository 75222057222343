import { useState } from 'react';

import {
    Box,
    CloseButton,
    Flex,
    useColorModeValue,
    Text,
    Image,
    Collapse,
    icon
} from '@chakra-ui/react'

import logo from '../../assets/logo.png';
import LinkItems from '../../config/sidenavItems'
import NavItem from './NavItem';

const SidebarContent = ({ onClose, ...rest }) => {

    const [openMenu, setOpenMenu] = useState(null); // Track which menu is expanded

    const handleToggle = (id) => {
        setOpenMenu((prev) => (prev === id ? null : id));
    };


    return (
        <Box
            as="nav"
            role="navigation"
            aria-label="Sidebar"
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}
        >
            <Flex h="20" alignItems="center" mx="8" mb="10" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    <Image w="150px" src={logo} alt='MiniTaka : Sell Anything' />
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => (
                <Box key={link.id}>
                    <NavItem
                        icon={link.icon}
                        href={link.href}
                        ariaLabel={link.ariaLabel}
                        onClick={() => link.children && handleToggle(link.id)}
                    >
                        {link.name}
                    </NavItem>
                    {link.children && (
                        <Collapse in={openMenu === link.id} animateOpacity>
                            <Box pl={6}>
                                {link.children.map((child) => (
                                    <NavItem
                                        key={child.id}
                                        href={child.href}
                                        ariaLabel={child.ariaLabel}
                                    >
                                        {child.name}
                                    </NavItem>
                                ))}
                            </Box>
                        </Collapse>
                    )}
                </Box>
            ))}
        </Box>
    )
};

export default SidebarContent;