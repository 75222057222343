import React from 'react';
import { Box, Text, Heading, VStack, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const StoreContent = () => (
  <VStack align="stretch" spacing={4}>

    <Heading fontSize="lg" fontWeight="bold">What Makes a Good Shop Name?</Heading>
    <Text color="gray.600" fontSize="sm">
      A good shop name is essential for building a memorable brand. Here are some tips to guide you:
    </Text>

    <List spacing={2} color="gray.600" fontSize="sm" mt={2} pl={4} styleType="decimal">
      <ListItem>
        <b>Short and Memorable:</b> Choose a name that’s easy to remember and doesn’t overwhelm. Aim for two or three words at most, like “EcoCrafts” or “UrbanNest.”
      </ListItem>
      <ListItem>
        <b>Relevant to Your Products or Industry:</b> Your shop name should hint at what you’re selling or the industry you’re in. For instance, “BakeHouse” for baked goods or “PetPals” for pet accessories.
      </ListItem>
      <ListItem>
        <b>Unique and Distinctive:</b> Ensure your name stands out from competitors and isn’t too generic. Research competitors in your niche to avoid similar names.
      </ListItem>
      <ListItem>
        <b>Easy to Spell and Pronounce:</b> This makes it easier for customers to find you online and talk about your brand.
      </ListItem>
      <ListItem>
        <b>Avoid Numbers and Special Characters:</b> They can make the name harder to remember and spell, especially in domain names.
      </ListItem>
      <ListItem>
        <b>Check Domain Availability:</b> Before finalizing a name, check if the corresponding domain (like .com or .shop) is available, as this will make it easier to establish an online presence.
      </ListItem>
    </List>

    <Heading fontSize="lg" fontWeight="bold" mt={6}>Building Your Brand</Heading>
    <Text color="gray.600" fontSize="sm" mt={2}>
      A strong shop name can help you stand out. Use it consistently to strengthen brand recognition across various platforms.
    </Text>

    <List spacing={2} color="gray.600" fontSize="sm" mt={2} pl={4} styleType="disc">
      <ListItem>
        <b>Incorporate It into Your Logo:</b> Design a logo that highlights your shop name. A visually appealing logo reinforces your name and helps it stick in customers’ minds.
      </ListItem>
      <ListItem>
        <b>Use It Consistently Across Platforms:</b> Consistency is key to brand recognition. Use your shop name across social media handles, email signatures, and product packaging.
      </ListItem>
      <ListItem>
        <b>Create a Slogan or Tagline:</b> Develop a short tagline that complements your shop name and communicates your brand’s values. For example, “EcoCrafts – Sustainable Goods for a Greener Planet.”
      </ListItem>
      <ListItem>
        <b>Tell a Story:</b> Share the story behind your shop name with customers. Whether it reflects your mission, values, or inspiration, a good story adds depth to your brand and makes it more relatable.
      </ListItem>
      <ListItem>
        <b>Optimize for SEO:</b> If your shop name is descriptive, incorporate it in your website’s keywords, product descriptions, and meta tags to improve your visibility on search engines.
      </ListItem>
      <ListItem>
        <b>Encourage Word-of-Mouth:</b> A catchy and memorable shop name can encourage customers to recommend your brand to others. Think about how your name sounds when spoken aloud and whether it’s easy to share.
      </ListItem>
    </List>

  </VStack>
);

const ProductSidePanelContent = () => (
  <VStack align="stretch" spacing={4}>
    
    <Heading fontSize="lg" fontWeight="bold">Adding a Product</Heading>
    <Text color="gray.600" fontSize="sm">
      A well-crafted product listing is essential to attract and convert customers. Here’s a guide to help you create a standout listing.
    </Text>

    {/* What Makes a Perfect Product Listing */}
    <Heading fontSize="md" fontWeight="bold" mt={4}>What Makes a Perfect Product Listing?</Heading>
    <List spacing={2} color="gray.600" fontSize="sm" mt={2} pl={4} styleType="decimal">
      <ListItem>
        <b>Product Title:</b> Make your product title clear and descriptive. Avoid overly long titles but include essential keywords (e.g., "Organic Cotton T-Shirt - Eco-Friendly, White").
      </ListItem>
      <ListItem>
        <b>Category Selection:</b> Choose a category that best fits your product. This makes it easier for customers to find your product and improves search results.
      </ListItem>
      <ListItem>
        <b>Detailed Descriptions:</b> Write a concise, informative description that includes the benefits and unique features of the product. Think about what the customer would want to know.
      </ListItem>
      <ListItem>
        <b>Quality Images:</b> Use clear, high-resolution photos to showcase the product from multiple angles. Good images are crucial for online sales, as they help customers visualize the product.
      </ListItem>
      <ListItem>
        <b>Pricing and Inventory:</b> Ensure your pricing is competitive and transparent. Update inventory levels regularly to maintain credibility with customers.
      </ListItem>
    </List>

    {/* Importance of Quality Images */}
    <Heading fontSize="md" fontWeight="bold" mt={4}>Why Quality Images Matter</Heading>
    <Text color="gray.600" fontSize="sm" mt={2}>
      High-quality images play a huge role in online shopping. They help build trust and allow customers to view product details closely, making it more likely for them to make a purchase.
    </Text>
    <List spacing={2} color="gray.600" fontSize="sm" mt={2} pl={4} styleType="disc">
      <ListItem>Use a simple, clean background to highlight the product.</ListItem>
      <ListItem>Take multiple photos showing different angles and close-up details.</ListItem>
      <ListItem>Ensure good lighting to avoid shadows or dark spots.</ListItem>
      <ListItem>Use a high-resolution camera or smartphone to ensure image clarity.</ListItem>
    </List>

    {/* Tips for Taking Good Product Photos */}
    <Heading fontSize="md" fontWeight="bold" mt={4}>Tips for Taking Great Product Photos</Heading>
    <List spacing={2} color="gray.600" fontSize="sm" mt={2} pl={4} styleType="decimal">
      <ListItem>
        <b>Lighting:</b> Natural lighting is best for capturing colors accurately. If using artificial light, make sure it’s diffused to avoid harsh shadows.
      </ListItem>
      <ListItem>
        <b>Background:</b> A plain white or neutral background works well for most products, ensuring the product is the focus.
      </ListItem>
      <ListItem>
        <b>Angles and Composition:</b> Capture the product from various angles—front, side, back, and any unique details. Think about how a customer would look at the item in person.
      </ListItem>
      <ListItem>
        <b>Close-Up Shots:</b> Show textures, materials, and finer details, especially for products with intricate designs or unique materials.
      </ListItem>
      <ListItem>
        <b>Edit Images:</b> Use basic photo editing tools to adjust brightness, contrast, and sharpness, but avoid over-editing as it can misrepresent the product.
      </ListItem>
    </List>

    {/* Crafting Effective Product Descriptions */}
    <Heading fontSize="md" fontWeight="bold" mt={4}>Crafting Effective Descriptions</Heading>
    <Text color="gray.600" fontSize="sm" mt={2}>
      A good product description answers common customer questions and highlights the benefits. Describe the key features and any special materials, and mention the size, color, or other variations if applicable.
    </Text>
    <List spacing={2} color="gray.600" fontSize="sm" mt={2} pl={4} styleType="disc">
      <ListItem>Use bullet points for easy readability.</ListItem>
      <ListItem>Highlight unique selling points or features.</ListItem>
      <ListItem>Be clear and concise, avoiding unnecessary jargon.</ListItem>
      <ListItem>Include dimensions, materials, and care instructions if applicable.</ListItem>
    </List>
  </VStack>
);


const SidePanel = ({ activeStep }) => (
    <VStack align="stretch" spacing={4} height="100%">
        <Heading fontSize="lg">Get ready to sell</Heading>
        <Text color="gray.600">
            Here's a guide to get started. As your business grows, you’ll get fresh tips and insights here.
        </Text>

        {/* Setup Progress */}
        {/* <Box bg="gray.50" p={4} rounded="md" shadow="sm">
            <Text fontSize="sm" color="gray.600">Setup guide</Text>
            <Text fontWeight="bold" mt={2}>{activeStep + 1} / {steps.length} completed</Text>
        </Box> */}

        {/* Conditional Content Based on Active Step */}
        {activeStep === 0 && (
            <StoreContent />
        )}

        {activeStep === 1 && (
            <ProductSidePanelContent />
        )}

        {/* Add more conditions as needed for other steps */}
    </VStack>
);

export default SidePanel;