import { VStack, Heading, Text, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { headerVariants, textVariants } from '../../../utils/animations/variants';

const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const FeaturesHeader = ({ comingSoon }) => (
  <VStack spacing={4} mb={12} textAlign="center" id="features">
    <Box position="relative">
      <MotionHeading
        as="h2"
        fontSize={{ base: "3xl", md: "4xl" }}
        fontWeight={comingSoon ? "bold" : "extrabold"}
        bgGradient="linear(to-r, teal.500, blue.500)"
        bgClip="text"
        variants={headerVariants}
        initial="hidden"
        animate="visible"
      >
        {comingSoon ? "Features we are offering" : "Features"}
      </MotionHeading>
      <Box
        position="absolute"
        bottom="-2px"
        left="50%"
        transform="translateX(-50%)"
        width="60px"
        height="4px"
        bgGradient="linear(to-r, teal.500, blue.500)"
        rounded="full"
      />
    </Box>

    <MotionText
      fontSize={{ base: "md", md: "lg" }}
      color="gray.600"
      maxW="2xl"
      variants={textVariants}
      initial="hidden"
      animate="visible"
    >
      Discover all the powerful features designed to simplify selling and elevate your business. 
    </MotionText>
  </VStack>
);

export default FeaturesHeader;