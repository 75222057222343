import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { AuthPage } from "supertokens-auth-react/ui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import { useNavigate, useLocation } from "react-router-dom";

const SignIn = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const showSignUp = new URLSearchParams(location.search).get("show") === "signup";
    console.log(showSignUp);
    return (
        <Flex align="center" justify="center">
            <Box>
                <AuthPage preBuiltUIList={[EmailPasswordPreBuiltUI, ThirdPartyPreBuiltUI]} navigate={navigate} isSignUp={showSignUp} />
            </Box>
        </Flex>
    );
};

export default SignIn;