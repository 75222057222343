// pages/Home.js
import React from "react";
import { chakra, Box, VStack, Container } from "@chakra-ui/react";
import { motion } from "framer-motion";
import EmailForm from "./EmailForm";
import FeatureSection from "./FeatureSection";
import FeatureList from "./Features";
import Pricing from "./Pricing";

const MotionHeading = motion(chakra.h1);
const MotionText = motion(chakra.p);

const HeroContent = () => (
    <VStack spacing={4} align="center">
        <MotionHeading
            fontSize={{ base: "4xl", md: "5xl" }}
            fontWeight={{ base: "bold", md: "extrabold" }}
            color="gray.900"
            lineHeight="shorter"
            textAlign="center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            Empower Your Homegrown Business
            <chakra.span
                display="block"
                bgGradient="linear(to-r, teal.500, blue.500)"
                bgClip="text"
            >
                Sell, Manage, and Grow with Ease!
            </chakra.span>
        </MotionHeading>

        <MotionText
            fontSize={{ base: "lg", md: "xl" }}
            color="gray.500"
            lineHeight="base"
            textAlign="center"
            maxW="2xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
        >
            Elevate your homegrown business with our user-friendly eCommerce platform,
            designed to simplify selling and foster customer connections.
        </MotionText>
    </VStack>
);

const SellerHome = () => {
    return (
        <>
            {/* Main Content */}
            <Box
                as="section"
                pt={{ base: 16, md: 20 }}
                pb={{ base: 12, md: 16 }}
                bg="gray.50"
            >
                <Container maxW="7xl">
                    <HeroContent />

                    {/* Email Form */}
                    {/* <EmailForm /> */}

                    {/* Features Section */}
                    <FeatureSection />
                </Container>
            </Box>

            <FeatureList />
            <Pricing />
        </>
    );
};

export default SellerHome;
