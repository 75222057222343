import { Box, Text, VStack } from '@chakra-ui/react';

export default function MetricCard({ title, value, change, isPositive }) {
  return (
    <Box p={6} bg="white" borderRadius="lg" shadow="sm" flex="1">
      <VStack align="start" spacing={1}>
        <Text color="gray.600" fontSize="sm">
          {title}
        </Text>
        <Text fontSize="2xl" fontWeight="bold">
          {value}
        </Text>
        <Text fontSize="sm" color={isPositive ? 'green.500' : 'red.500'}>
          {isPositive ? '+' : '-'}{change} from previous period
        </Text>
      </VStack>
    </Box>
  );
}