import React, { createContext, useState, useContext } from 'react';

// Create UserContext
const UserContext = createContext();

// Custom Hook to Access UserContext
export const useUser = () => {
  return useContext(UserContext);
};

// UserProvider Component
export const UserProvider = ({ children }) => {
  // State to Store User Information
  const [user, setUser] = useState({
    name: 'Justina Clark',
    role: 'User',
    avatar: 'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9',
  });

  // Function to Update User
  const updateUser = (newUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...newUserData,
    }));
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};