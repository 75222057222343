// apiConfig.js
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_ACTIVE_VERSION = process.env.REACT_APP_API_ACTIVE_VERSION;

console.log(process.env);

// Export the baseUrl based on current environment
export const BASE_URL = API_BASE_URL || 'http://localhost';
// REACT_APP_API_BASE_URL=http://localhost
const createApiUrl = (endpoint) => `${BASE_URL}/api/${API_ACTIVE_VERSION}/${endpoint}`;

// Define common API routes (you can add more here)
export const CHECK_IF_USER_EXIST = {
    method: 'POST',
    url: createApiUrl('check-user'),  // endpoint relative to base URL
};

export const AUTOCOMPLETE_ADDRESS = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/autocomplete-address`,  // endpoint relative to base URL
};

export const FETCH_STORE_DETAILS = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/stores/:store_name`,  // endpoint relative to base URL
};

export const UPDATE_STORE_NAME = {
    method: 'POST',
    url: `${BASE_URL}/api/v1/stores/update-shop-name`,
};

export const GET_ONBOARDING_STEPS_STATE = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/onboarding/state/:userId`,
};

export const UPDATE_ONBOARDING_STEPS = {
    method: 'POST',
    url: `${BASE_URL}/api/v1/onboarding/state/:userId`,
};

// Product related routes

export const GET_STORE_BY_USERID = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/stores/user/:userId`,
};

export const GET_STORE_DETAILS = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/stores/details`,
};

export const UPDATE_STORE_DETAILS = {
    method: 'PUT',
    url: `${BASE_URL}/api/v1/stores/details`,
};

export const GET_PRODUCTS_BY_STORE = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/products/store/:storeId`,
};

export const GET_PRODUCTS_BY_SELLER = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/seller/:sellerId/products`,
};

export const CREATE_PRODUCT = {
    method: 'POST',
    url: createApiUrl('products'),
};

export const UPDATE_PRODUCT = {
    method: 'PUT',
    url: `${BASE_URL}/api/v1/products/:productId`,
};

export const UPDATE_PRODUCT_STATUS = {
    method: 'PUT',
    url: `${BASE_URL}/api/v1/products/:productId/status`,
};

export const DELETE_PRODUCT = {
    method: 'DELETE',
    url: `${BASE_URL}/api/v1/products/:productId`,
};

export const CREATE_SAMPLE_PRODUCT = {
    method: 'POST',
    url: `${BASE_URL}/api/v1/products/sample`,
};

export const GET_PRODUCT_DETAILS = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/products/:productId`,
};

// Order related API routes

export const GET_ALL_ORDERS_BY_STORE = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/store/:storeId/orders`,
};

export const GET_ORDER_DETAILS = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/orders/:orderId`,
};

export const CREATE_ORDER = {
    method: 'POST',
    url: `${BASE_URL}/api/v1/orders`,
};

export const UPDATE_ORDER = {
    method: 'PUT',
    url: `${BASE_URL}/api/v1/orders/:orderId`,
};

export const UPDATE_ORDER_STATUS = {
    method: 'PATCH',
    url: `${BASE_URL}/api/v1/orders/:orderId/status`,
};

export const DELETE_ORDER = {
    method: 'DELETE',
    url: `${BASE_URL}/api/v1/orders/:orderId`,
};

export const GET_ORDER_STATUS_HISTORY = {
    method: 'GET',
    url: `${BASE_URL}/api/v1/orders/:orderId/status-history`,
};

export const CHECK_PRODUCT_NAME = {
    method: 'POST',
    url: `${BASE_URL}/api/v1/products/check-name`,
};

export const SEND_CONTACT_REQUEST = {
    method: 'POST',
    url: `${BASE_URL}/api/v1/contact`,
};