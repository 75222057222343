// Map Chakra color tokens to hex values for animations
export const colorMap = {
    red: {
      200: '#FEB2B2',
      400: '#FC8181',
      600: '#E53E3E'
    },
    green: {
      200: '#9AE6B4',
      400: '#48BB78',
      600: '#2F855A'
    },
    blue: {
      200: '#90CDF4',
      400: '#4299E1',
      600: '#2B6CB0'
    },
    yellow: {
      200: '#FAF089',
      400: '#ECC94B',
      600: '#B7791F'
    },
    purple: {
      200: '#B794F4',
      400: '#9F7AEA',
      600: '#6B46C1'
    },
    teal: {
      200: '#81E6D9',
      400: '#38B2AC',
      600: '#2C7A7B'
    },
    orange: {
      200: '#FBD38D',
      400: '#ED8936',
      600: '#C05621'
    }
  };