import { Box, Container, SimpleGrid } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { featuresList } from './featuresList';
import FeaturesHeader from './FeaturesHeader';
import FeatureCard from './FeatureCard';

import { cardVariants } from '../../../utils/animations/variants';

const MotionSimpleGrid = motion(SimpleGrid);

const FeaturesList = ({ comingSoon }) => {
  return (
    <Box bg="white" py={16}>
      <Container maxW="7xl">
        <FeaturesHeader comingSoon={comingSoon} />

        <MotionSimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing={8}
          px={{ base: 4, md: 8 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          {featuresList.map((feature, index) => (
            <motion.div
              key={feature.id}
              custom={index}
              variants={cardVariants}
              initial="hidden"
              animate="visible"
            >
              <FeatureCard {...feature} />
            </motion.div>
          ))}
        </MotionSimpleGrid>
      </Container>
    </Box>
  );
};

export default FeaturesList;