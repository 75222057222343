import { Box, Text, Heading, Button, HStack, VStack, Icon, Flex, useBreakpointValue } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import apiHandler from '../../../services/apiHandler';

import { GET_ONBOARDING_STEPS_STATE, UPDATE_ONBOARDING_STEPS, GET_STORE_BY_USERID } from "../../../services/apiConfig"

import APP_ROUTES from '../../../constant/appPath';

import steps from '../../../config/getStartedSteps';
import ShopNameStep from "../../../components/ShopNameStep"
import SidePanel from './SidePanel';

const GettingStarted = () => {
    const [activeStep, setActiveStep] = useState(0); // Track the currently active step
    const [completedSteps, setCompletedSteps] = useState([]); // Track completed steps
    const [storeDetails, setStoreDetails] = useState(null); // Track store details

    const [collapsedSteps, setCollapsedSteps] = useState(steps.map(() => true)); // Track collapsed state for each step
    const navigate = useNavigate();
    const isInitialMount = useRef(true);

    const userId = 1; // Assume user ID is 1

    useEffect(() => {
        // Fetch onboarding state
        const fetchOnboardingState = async () => {
            try {
                const response = await apiHandler({ options: GET_ONBOARDING_STEPS_STATE, urlParams: { userId: userId } });
                const { step_number, completed_steps } = response.data;
                setActiveStep((step_number + 1 || 0));
                setCompletedSteps(completed_steps || []);
            } catch (error) {
                console.error("Failed to fetch onboarding state:", error);
            }
        };

        if (isInitialMount.current) {
            fetchOnboardingState();
            isInitialMount.current = false;
        }
    }, [userId]);

    // Fetch store details
    useEffect(() => {
        const fetchStoreDetails = async () => {
            console.log("Fetching store details...");
            try {
                const response = await apiHandler({ options: GET_STORE_BY_USERID, urlParams: { userId: userId } });

                setStoreDetails(response.data);
            } catch (error) {
                console.error("Failed to fetch store details:", error);
            }
        };

        fetchStoreDetails();
    }, [userId]);



    const toggleCollapse = (index) => {
        setCollapsedSteps((prev) => prev.map((collapsed, i) => (i === index ? !collapsed : collapsed)));
    };

    const panelWidth = useBreakpointValue({ base: "100%", md: "70%", lg: "60%" });
    const sidePanelWidth = useBreakpointValue({ base: "100%", md: "30%", lg: "40%" });

    // Mark step as complete
    const markStepComplete = async (index) => {
        if (!completedSteps.includes(index)) {
            setCompletedSteps([...completedSteps, index]);
            try {
                await apiHandler({ options: UPDATE_ONBOARDING_STEPS, urlParams: { userId: userId }, data: { step_number: index } });
                // await axios.post(`/api/onboarding/state/${userId}`, { step_number: index });
            } catch (error) {
                console.error("Failed to update onboarding progress:", error);
            }
        }
        setActiveStep(activeStep + 1);
    };

    const handleStepClick = (index) => {
        setActiveStep(index);
    };

    const actionHandlers = {
        SETUP_DOMAIN: () => navigate(APP_ROUTES.DOMAIN_OPTIONS_SETTINGS, { state: { isFromGettingStarted: true } }),
        ADD_PRODUCT: () => navigate(APP_ROUTES.ADD_PRODUCT, { state: { isFromGettingStarted: true } }),
        IMPORT_PRODUCT: () => navigate(APP_ROUTES.IMPORT_PRODUCT, { state: { isFromGettingStarted: true } }),
        ADD_SAMPLE_PRODUCT: () => navigate(APP_ROUTES.ADD_SAMPLE_PRODUCT, { state: { isFromGettingStarted: true } }),
    };

    const handleStepAction = (actionType) => actionHandlers[actionType]?.();

    const renderHeading = () => {
        return (
            <VStack align="stretch" spacing={4}>
                <Heading as="h1" size="md">Quick setup guide</Heading>
                <Text color="gray.600" fontSize="xs">
                    Here's a guide to get you started. <br /> As your business grows, you’ll get fresh tips and insights here.
                    You can always come back to this guide to complete the setup. <br /><br />Let's get started!
                </Text>
                {/* Progress Indicator */}
                <Text fontWeight="bold" fontSize="xs">{completedSteps.length} / {steps.length} completed</Text>
            </VStack>
        );
    };

    const progressIndicator = (index) => {
        return (

            <Box
                as="button"
                aria-label={`Step ${index}`}
                onClick={() => handleStepClick(index)}
                position="relative"
                boxSize="16px" // Smaller circle
                borderRadius="full"
                borderWidth="2px"
                border={activeStep === index ? "1px" : "0px"}
                rounded="md"
                shadow="sm"
                borderColor={
                    completedSteps.includes(index)
                        ? "green.400"
                        : activeStep === index
                            ? "green.500"
                            : "black"
                }
                bg={completedSteps.includes(index) ? "green.400" : "transparent"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={5}
                _hover={{ bg: "gray.50", cursor: "pointer" }}
            >
                {completedSteps.includes(index) && <Icon as={CheckIcon} boxSize="10px" color="white" />}
            </Box>
        );
    };

    const renderActions = (step, index) => {
        return (
            <HStack spacing={2} mt={2}>
                {step.actions?.map((action, idx) => (
                    <Button
                        key={idx}
                        size="sm"
                        colorScheme={action.primary ? "blue" : "gray"}
                        variant={action.primary ? "solid" : "outline"}
                        onClick={() => handleStepAction(action.actionType)}
                    >
                        {action.label}
                    </Button>
                ))}
                {step.next !== false && (
                    <Button
                        size="sm"
                        variant="link"
                        colorScheme="blue"
                        position="absolute"
                        bottom={7}
                        right={10}
                        onClick={() => markStepComplete(index)}
                    >
                        Next
                    </Button>
                )}
            </HStack>
        );
    };

    return (
        <Flex gap={6} align="stretch">
            {/* Getting Started Section (Left) */}
            <Box flex="1" maxW={panelWidth} p={6}>
                <VStack align="stretch" spacing={4} position="relative">
                    {renderHeading()}

                    {/* Steps with vertical line and circle */}
                    <VStack spacing={4} align="stretch" position="relative">
                        <Box
                            position="absolute"
                            top="10"
                            left="15px"
                            width="2px"
                            bg="gray.200"
                            zIndex="-1"
                        />

                        {steps.map((step, index) => (

                            <HStack key={index} align="start" spacing={4} position="relative" onClick={() => handleStepClick(index)} cursor="pointer">
                                {progressIndicator(index)}
                                {/* Step Content */}
                                <VStack align="stretch" spacing={2} flex="1" border={activeStep === index ? "1px" : "0px"} borderColor="gray.200" p={4} rounded="md" shadow="sm">
                                    <Text fontWeight="bold" fontSize="md">{step.title}</Text>
                                    {/* Conditionally render content if step is expanded */}
                                    {activeStep === index && (
                                        <>
                                            <Text color="gray.600" fontSize="sm">{step.description}</Text>
                                            {step.component && step.component === "shopName" && <ShopNameStep storeDetails={storeDetails} />}
                                            {step.actions && renderActions(step, index)}
                                        </>
                                    )}
                                </VStack>
                            </HStack>
                        ))}


                    </VStack>
                </VStack>
            </Box>

            {/* Right Side Panel */}
            <Box
                flex="0.5"
                maxW={sidePanelWidth}
                bg="gray.100"
                p={6}
                rounded="lg"
                shadow="md"
                height="full"
            >
                <SidePanel activeStep={activeStep} />
            </Box>
        </Flex>

    );
};

export default GettingStarted;