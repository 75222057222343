import SuperTokens from "supertokens-auth-react";
import ThirdParty, { Google, Facebook } from "supertokens-auth-react/recipe/thirdparty";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";

export const initSuperTokens = () => {
    SuperTokens.init({
        appInfo: {
            appName: "MiniTaka",
            apiDomain: process.env.REACT_APP_API_DOMAIN,
            websiteDomain: process.env.REACT_APP_WEBSITE_DOMAIN,
            apiBasePath: "/auth",
            websiteBasePath: "/login",
        },
        disableAuthRoute: true,
        termsOfServiceLink: process.env.REACT_APP_WEBSITE_DOMAIN+"/legal/terms-and-conditions",
        privacyPolicyLink: process.env.REACT_APP_WEBSITE_DOMAIN+"/legal/privacy-policy",
        recipeList: [
            ThirdParty.init({
                signInAndUpFeature: {
                    providers: [
                        Google.init(),
                        Facebook.init()
                    ]
                }
            }),
            EmailPassword.init({
                mode: "OPTIONAL", // or "REQUIRED"
              }),
            Session.init()
        ],
        
    });
};