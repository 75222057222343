'use client'

import { Drawer, DrawerContent, useDisclosure, useBreakpointValue, useColorModeValue, Flex } from '@chakra-ui/react'
import SidebarContent from './SidebarContent';
import TopHeaderNav from './TopHeaderNav';

// import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

// const Breadcrumbs = () => (
//     <Breadcrumb fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
//         <BreadcrumbItem>
//             <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
//         </BreadcrumbItem>
//         <BreadcrumbItem>
//             <BreadcrumbLink href="/settings">Settings</BreadcrumbLink>
//         </BreadcrumbItem>
//     </Breadcrumb>
// );


const SidebarWithHeader = () => {
    const isDesktop = useBreakpointValue({ base: false, md: true });
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            {isDesktop ? (
                <SidebarContent onClose={onClose} />
            ) : (
                <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>
            )}
            {/* <Flex direction="column"> */}
            <TopHeaderNav onOpen={onOpen} />
            {/* <Breadcrumbs /> */}
            {/* </Flex> */}
        </>
    )
}

export default SidebarWithHeader