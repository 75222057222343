import { Box, Button, Flex, Grid, Heading, Image, Text, VStack, Stack, Link } from "@chakra-ui/react";

const Header = () => (
  <Flex as="header" p={4} justify="space-between" align="center" bg="gray.800" color="white">
    <Heading size="md">MyShop</Heading>
    <Flex gap={4}>
      <Button variant="ghost" colorScheme="teal">Shop</Button>
      <Button variant="ghost" colorScheme="teal">About</Button>
      <Button variant="ghost" colorScheme="teal">Contact</Button>
    </Flex>
  </Flex>
);

const Banner = () => (
  <Flex align="center" justify="center" h="400px" bgImage="url('https://via.placeholder.com/1200x400')" bgSize="cover" color="white">
    <VStack spacing={4}>
      <Heading>Discover Our New Collection</Heading>
      <Button colorScheme="teal" variant="solid" size="lg">Shop Now</Button>
    </VStack>
  </Flex>
);

const ProductGrid = () => (
  <Box p={6}>
    <Heading size="lg" mb={6} textAlign="center">Featured Products</Heading>
    <Grid templateColumns="repeat(auto-fit, minmax(240px, 1fr))" gap={6}>
      {[...Array(8)].map((_, index) => (
        <VStack key={index} p={4} borderWidth="1px" rounded="lg" bg="white" shadow="md" spacing={3}>
          <Image boxSize="200px" src={`https://via.placeholder.com/200?text=Product+${index + 1}`} alt={`Product ${index + 1}`} />
          <Text fontWeight="bold">Product {index + 1}</Text>
          <Text color="gray.500">$49.99</Text>
          <Button colorScheme="teal" size="sm">Add to Cart</Button>
        </VStack>
      ))}
    </Grid>
  </Box>
);

const Footer = () => (
  <Flex as="footer" p={6} bg="gray.800" color="white" justify="center" align="center">
    <Text>© 2024 MyShop. All rights reserved.</Text>
  </Flex>
);

const Template1 = () => (
  <Box bg="gray.50" minH="100vh">
    <Header />
    <Banner />
    <ProductGrid />
    <Footer />
  </Box>
);

export default Template1;