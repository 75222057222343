import axios from 'axios';

// Centralized API handler
const apiHandler = async ({ options, data = {}, headers = {}, params = {}, urlParams = {} }) => {
    console.log("API handler options:", options, params, urlParams);
    const { method } = options;
    let url = options.url;

    Object.keys(urlParams).forEach((key) => {
        url = url.replace(`:${key}`, urlParams[key]);
    });

    console.log("API call:", method, url, params);

    const config = {
        method: options.method,
        url,
        headers: { 'Content-Type': 'application/json' },
        ...params,
        ...(options.method === 'GET' ? { params } : { data }),
    };

    try {
        // Call the API using axios with dynamic parameters
        const response = await axios(config);
        // TODO: Work on status code etc
        // Return the response data (you can customize this as per your needs)
        return response.data;

    } catch (error) {
        // Handle and throw the error, or customize as needed
        console.error("API call error:", error);
        throw error;
    }
};

export default apiHandler;