import React, { useState, useEffect } from "react";
import { Box, Flex, Button, Heading, Text, Input, Checkbox, CheckboxGroup, Stack, HStack, useRadioGroup, IconButton, List, ListItem } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Store, Truck, Music, UserCog, Printer, HelpCircle, MapPin, ArrowRight, X } from 'lucide-react';

import RadioCard from "../../components/RadioCard";
import apiHandler from "../../services/apiHandler";
import RenderCityInput from "../../components/RenderCityInput";
import { CHECK_IF_USER_EXIST, AUTOCOMPLETE_ADDRESS } from "../../services/apiConfig";

const Onboarding = () => {
    const [step, setStep] = useState("checkUser");
    const [email, setEmail] = useState("");
    const [userExists, setUserExists] = useState(false);
    const [productOptions, setProductOptions] = useState([]);
    const [sellingOnline, setSellingOnline] = useState([]);
    const [city, setCity] = useState("");
    const [citySuggestions, setCitySuggestions] = useState([]);
    const [isCitySelected, setIsCitySelected] = useState(false); // Track if city is selected
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const emailFromUrl = params.get("email");
        console.log("Email from URL:", emailFromUrl);   
        if (emailFromUrl) {
            setEmail(emailFromUrl);
            checkUserExists(emailFromUrl);
        } else {
            console.log("No email found in URL");
            setUserExists(false);
            setStep("questions");
            setLoading(false);
        }
    }, [location]);

    const checkUserExists = async (email) => {
        try {
            const response = await apiHandler({
                options: CHECK_IF_USER_EXIST,
                data: { email }
            });
            if (response.success) {
                setUserExists(true);
                setStep("signin");
            } else {
                setUserExists(false);
                setStep("questions");
            }
        } catch (error) {
            console.error("Error checking user existence:", error);
        } finally {
            setLoading(false);
        }
    };

    const productTypes = [
        {
          title: "Products I buy or make myself",
          note: "Shipped by me",
          icon: Store
        },
        {
          title: "Products I dropship",
          note: "Sourced and shipped by a third party",
          icon: Truck
        },
        {
          title: "Digital products",
          note: "Music, digital art, NFTs",
          icon: Music
        },
        {
          title: "Services",
          note: "Coaching, housekeeping, consulting",
          icon: UserCog
        },
        {
          title: "Print-on-demand products",
          note: "My designs, printed and shipped by a third party",
          icon: Printer
        },
        {
          title: "I'll decide later",
          note: "",
          icon: HelpCircle
        }
      ];

    const sellingOptions = [
        "I am starting fresh",
        "I have my online store and want to move it"
    ];

    // Debounced effect for city autocomplete
    useEffect(() => {
        if (isCitySelected) return; // Skip API call if city is selected

        const delayDebounceFn = setTimeout(async () => {
            if (city.length > 2) {
                try {
                    const response = await apiHandler({
                        options: AUTOCOMPLETE_ADDRESS,
                        params: { input: city, types: "(cities)", country: "IN" }
                    });
                    setCitySuggestions(response || []);
                } catch (error) {
                    console.error("Error fetching city suggestions:", error);
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [city, isCitySelected]); // Depend on `isCitySelected` to control API call timing

    const handleCityChange = (event) => {
        setCity(event.target.value);
        setIsCitySelected(false); // Reset if user types manually
    };

    const handleSelectCity = (selectedCity) => {
        setCity(selectedCity);
        setIsCitySelected(true); // Prevent further API calls after selection
        setCitySuggestions([]);
    };

    const clearCitySelection = () => {
        setCity("");
        setIsCitySelected(false); // Allow searching again
        setCitySuggestions([]); // Clear suggestions
    };

    const ProductQuestion = ({ productOptions, setProductOptions, productTypes }) => {
        const { getRootProps, getRadioProps } = useRadioGroup({
            name: "productOptions",
            value: productOptions,
            onChange: setProductOptions,
        });
    
        const group = getRootProps();
    
        return (
            <Box textAlign="left" mb={6}>
                <Heading size="md" mb={2}>What do you sell?</Heading>
                <HStack {...group} wrap="wrap" spacing={4}>
                    {productTypes.map((type, index) => {
                        const radio = getRadioProps({ value: type.title });
                        return (
                            <RadioCard
                                key={index}
                                {...radio}
                                width="50%"
                                _checked={{
                                    bg: "#f73859",
                                    color: "white",
                                    borderColor: "#f73859",
                                }}
                            >
                                {type.title}
                                <Text fontSize="sm" color="gray.500">
                                    {type.note}
                                </Text>
                            </RadioCard>
                        );
                    })}
                </HStack>
            </Box>
        );
    };

    const SellingOnlineQuestion = ({ sellingOnline, setSellingOnline, sellingOptions }) => {
        const { getRootProps, getRadioProps } = useRadioGroup({
            name: "sellingOnline",
            value: sellingOnline,
            onChange: setSellingOnline,
        });
    
        const group = getRootProps();
    
        return (
            <Box textAlign="left" mb={6}>
                <Heading size="md" mb={2}>Are you selling online?</Heading>
                <HStack {...group} wrap="wrap" spacing={4}>
                    {sellingOptions.map((option, index) => {
                        const radio = getRadioProps({ value: option });
                        return (
                            <RadioCard
                                key={index}
                                {...radio}
                                width="50%"
                                _checked={{
                                    bg: "#f73859",
                                    color: "white",
                                    borderColor: "#f73859",
                                }}
                            >
                                {option}
                            </RadioCard>
                        );
                    })}
                </HStack>
            </Box>
        );
    };
    

    // const RenderCityInput = ({ city, citySuggestions, handleCityChange, handleSelectCity, clearCitySelection }) => (
    //     <Box textAlign="left" mb={6} position="relative">
    //         <Heading size="md" mb={2}>City</Heading>
    //         <Box display="flex" alignItems="center">
    //             <Input
    //                 value={city}
    //                 onChange={handleCityChange}
    //                 placeholder="Enter your city (India only)"
    //                 autoComplete="off"
    //                 pr={city ? "2.5rem" : "0.5rem"} // Add padding for clear button
    //                 onFocus={(e) => e.target.select()} // Keep focus on the input while typing
    //             />
    //             {city && (
    //                 <IconButton
    //                     aria-label="Clear city selection"
    //                     icon={<CloseIcon />}
    //                     size="sm"
    //                     position="absolute"
    //                     right="10px"
    //                     onClick={(e) => {
    //                         e.stopPropagation();
    //                         clearCitySelection();
    //                     }}
    //                 />
    //             )}
    //         </Box>
    //         {citySuggestions.length > 0 && !isCitySelected && (
    //             <List
    //                 mt={2}
    //                 borderWidth="1px"
    //                 borderRadius="md"
    //                 bg="white"
    //                 maxH="200px"
    //                 overflowY="auto"
    //                 boxShadow="md"
    //                 zIndex="10"
    //                 position="absolute"
    //                 width="100%"
    //             >
    //                 {citySuggestions.map((suggestion, index) => (
    //                     <ListItem
    //                         key={index}
    //                         p={2}
    //                         cursor="pointer"
    //                         _hover={{ bg: "gray.100" }}
    //                         onMouseDown={(e) => e.preventDefault()} // Prevent blur on selection
    //                         onClick={() => handleSelectCity(suggestion.description)}
    //                     >
    //                         {suggestion.description}
    //                     </ListItem>
    //                 ))}
    //             </List>
    //         )}
    //     </Box>
    // );

    const renderSkipMessage = () => (
        <Box textAlign="center" mb={6}>
            <Text color="gray.600">You may skip by leaving options unselected and clicking "Next."</Text>
        </Box>
    );

    const renderQuestions = () => (
        <Box textAlign="center">
            <SellingOnlineQuestion sellingOptions={sellingOptions} />
            <ProductQuestion productTypes={productTypes} />
            
            <RenderCityInput
                city={city}
                setCity={setCity}
                isCitySelected={isCitySelected}
                setIsCitySelected={setIsCitySelected}
            />
            {renderSkipMessage()}
            <Button mt={4} onClick={() => setStep("signup")}>
                Next
            </Button>
        </Box>
    );

    return (
        <Flex minH="100vh" align="center" justify="center">
            <Box w={{ base: "100%", md: "50%" }} p={8} textAlign="center">
                {loading ? (
                    <Heading mb={4}>Loading...</Heading>
                ) : step === "signin" ? (
                    navigate("/login")
                ) : step === "questions" ? (
                    renderQuestions()
                ) : step === "signup" ? (
                    navigate("/login?show=signup")
                ) : null}
            </Box>
        </Flex>
    );
};

export default Onboarding;