import { useState } from "react";
import axios from "axios";
import { Box, Container, SimpleGrid, VStack, Heading, Text, Button, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import { FiMail, FiMapPin } from "react-icons/fi";

import apiHandler from "../../services/apiHandler";
import { SEND_CONTACT_REQUEST } from "../../services/apiConfig";

const Contact = () => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        if (!formData.name || !formData.email || !formData.message) {
            alert("Please fill in all fields.");
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await apiHandler({
                options: SEND_CONTACT_REQUEST,
                data: { ...formData },
            });
            
            alert("Your message has been sent successfully!");
            setFormData({ name: "", email: "", message: "" });
        } catch (error) {
            console.error("Failed to send the message:", error);
            alert("Something went wrong. Please try again later.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box py={16}>
            <Container maxW="7xl">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <VStack align="stretch" spacing={8}>
                        <Box>
                            <Heading mb={4}>Get in Touch</Heading>
                            <Text color="gray.600">
                                Have questions? We'd love to hear from you. Send us a message
                                and we'll respond as soon as possible.
                            </Text>
                        </Box>

                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input name="name" placeholder="Your name" value={formData.name} onChange={handleInputChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input name="email" type="email" placeholder="your@email.com" value={formData.email} onChange={handleInputChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Message</FormLabel>
                                <Textarea name="message" placeholder="Your message" rows={6} value={formData.message} onChange={handleInputChange} />
                            </FormControl>
                            <Button colorScheme="teal" size="lg" w="full" onClick={handleSubmit} isLoading={isSubmitting}>
                                Send Message
                            </Button>
                        </VStack>
                    </VStack>

                    <VStack align="stretch" spacing={8}>
                        <Box bg="gray.50" p={8} rounded="lg">
                            <VStack spacing={6} align="stretch">
                                <Box>
                                    <Heading size="md" mb={2}>Email</Heading>
                                    <Text color="gray.600" display="flex" alignItems="center">
                                        <FiMail style={{ marginRight: "8px" }} />
                                        social@minitaka.com
                                    </Text>
                                </Box>
                                {/* <Box>
                <Heading size="md" mb={2}>Phone</Heading>
                <Text color="gray.600" display="flex" alignItems="center">
                  <FiPhone style={{ marginRight: "8px" }} />
                  +91 
                </Text>
              </Box> */}
                                <Box>
                                    <Heading size="md" mb={2}>Address</Heading>
                                    <Text color="gray.600" display="flex" alignItems="center">
                                        <FiMapPin style={{ marginRight: "8px" }} />
                                        Electronic City, Bengaluru, India
                                    </Text>
                                </Box>
                            </VStack>
                        </Box>
                    </VStack>
                </SimpleGrid>
            </Container>
        </Box>
    )
};

export default Contact;