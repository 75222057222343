import { Box, Text, Flex, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend } from 'recharts';
import EmptyState from './EmptyState';

const customerGrowthData = [
  { month: 'Jan', newCustomers: 400, returningCustomers: 240 },
  { month: 'Feb', newCustomers: 300, returningCustomers: 139 },
  { month: 'Mar', newCustomers: 200, returningCustomers: 980 },
  { month: 'Apr', newCustomers: 278, returningCustomers: 390 },
  { month: 'May', newCustomers: 189, returningCustomers: 480 },
  { month: 'Jun', newCustomers: 239, returningCustomers: 380 },
];

const topCustomers = [
  { name: 'John Doe', orders: 12, spent: 1234, lastOrder: '2024-01-01' },
  { name: 'Jane Smith', orders: 10, spent: 987, lastOrder: '2024-01-02' },
  { name: 'Bob Johnson', orders: 8, spent: 876, lastOrder: '2024-01-01' },
  { name: 'Alice Brown', orders: 7, spent: 765, lastOrder: '2024-01-03' },
  { name: 'Charlie Wilson', orders: 6, spent: 654, lastOrder: '2024-01-02' },
];


export default function CustomerChart({ isLoading = false, hasData = true }) {
  return (
    <Flex gap={6} direction={{ base: 'column', xl: 'row' }}>
      <Box flex={1} bg="white" p={6} borderRadius="lg" shadow="sm">
        <Text fontSize="lg" fontWeight="semibold">Customer Growth</Text>
        <Text fontSize="sm" color="gray.600" mb={6}>
          New vs returning customers over time
        </Text>

        <Box h="400px">
          {isLoading ? (
            <Flex h="full" align="center" justify="center">
              <Text>Loading...</Text>
            </Flex>
          ) : !hasData ? (
            <EmptyState />
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={customerGrowthData}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="month" />
                <YAxis />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="newCustomers"
                  stroke="#2196F3"
                  name="New Customers"
                  dot={{ r: 4 }}
                />
                <Line
                  type="monotone"
                  dataKey="returningCustomers"
                  stroke="#4CAF50"
                  name="Returning Customers"
                  dot={{ r: 4 }}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Box>

      <Box flex={1} bg="white" p={6} borderRadius="lg" shadow="sm">
        <Text fontSize="lg" fontWeight="semibold">Top Customers</Text>
        <Text fontSize="sm" color="gray.600" mb={6}>
          Your most valuable customers by order value
        </Text>

        {isLoading ? (
          <Flex h="400px" align="center" justify="center">
            <Text>Loading...</Text>
          </Flex>
        ) : !hasData ? (
          <EmptyState />
        ) : (
          <Table>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th isNumeric>Orders</Th>
                <Th isNumeric>Spent</Th>
                <Th>Last Order</Th>
              </Tr>
            </Thead>
            <Tbody>
              {topCustomers.map((customer) => (
                <Tr key={customer.name}>
                  <Td>{customer.name}</Td>
                  <Td isNumeric>{customer.orders}</Td>
                  <Td isNumeric>${customer.spent}</Td>
                  <Td>{customer.lastOrder}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </Flex>
  );
}