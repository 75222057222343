import { Box, Container, Heading, Text, SimpleGrid, VStack, Button, Image } from "@chakra-ui/react";

const pressReleases = [
  {
    title: "MiniTaka Raises $10M Series A to Empower Small Businesses",
    date: "March 15, 2024",
    source: "TechCrunch",
    image: "/assets/press/techcrunch.png"
  },
  {
    title: "How MiniTaka is Revolutionizing E-commerce for Small Businesses",
    date: "February 28, 2024",
    source: "Economic Times",
    image: "/assets/press/et.png"
  },
  {
    title: "MiniTaka Expands Operations to 100 Cities",
    date: "January 10, 2024",
    source: "YourStory",
    image: "/assets/press/yourstory.png"
  }
];

const Press = () => (
  <Box py={16}>
    <Container maxW="7xl">
      <VStack spacing={16} align="stretch">
        {/* Hero Section */}
        <VStack spacing={6} textAlign="center">
          <Heading 
            size="2xl" 
            bgGradient="linear(to-r, teal.500, blue.500)" 
            bgClip="text"
          >
            Press & Media
          </Heading>
          <Text fontSize="xl" color="gray.600" maxW="3xl" mx="auto">
            Latest news, press releases, and media coverage about MiniTaka.
          </Text>
        </VStack>

        {/* Press Releases */}
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
          {pressReleases.map((press) => (
            <Box 
              key={press.title} 
              p={6} 
              bg="white" 
              rounded="lg" 
              shadow="md"
              borderWidth={1}
              borderColor="gray.100"
            >
              <VStack align="stretch" spacing={4}>
                <Image 
                  src={press.image} 
                  alt={press.source} 
                  height="40px" 
                  objectFit="contain"
                />
                <Text fontSize="sm" color="gray.500">{press.date}</Text>
                <Heading size="md">{press.title}</Heading>
                <Text color="teal.500" fontSize="sm">
                  Read More →
                </Text>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>

        {/* Media Kit */}
        <Box>
          <Heading size="xl" mb={8}>Media Kit</Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
            <Box p={6} bg="white" rounded="lg" shadow="md">
              <VStack align="start" spacing={4}>
                <Heading size="md">Brand Assets</Heading>
                <Text color="gray.600">
                  Download our logo, brand guidelines, and other assets for press use.
                </Text>
                <Button colorScheme="teal" variant="outline">
                  Download Media Kit
                </Button>
              </VStack>
            </Box>
            <Box p={6} bg="white" rounded="lg" shadow="md">
              <VStack align="start" spacing={4}>
                <Heading size="md">Media Contact</Heading>
                <Text color="gray.600">
                  For press inquiries, please contact our media relations team:
                  press@minitaka.com
                </Text>
              </VStack>
            </Box>
          </SimpleGrid>
        </Box>
      </VStack>
    </Container>
  </Box>
);

export default Press;