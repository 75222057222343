// routes/SellerRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";

import { SessionAuth } from "supertokens-auth-react/recipe/session";

import LayoutSellerPortal from "../Layout/SellerPortal";

import Dashboard from "../pages/SellerPortal/Dashboard";
import ProductPage from "../pages/SellerPortal/ProductManagement";
import Details from "../pages/SellerPortal/ProductManagement/Details";

import OrdersPage from "../pages/SellerPortal/Orders";

import Reports from "../pages/SellerPortal/Reports";

import SettingsPage from '../pages/SellerPortal/Settings/';
import DomainOptions from '../pages/SellerPortal/Settings/DomainManagement';
import ManageStore from '../pages/SellerPortal/Settings/StoreManagement';
import UserPermissions from '../pages/SellerPortal/Settings/UserPermissions';
import PaymentSettings from '../pages/SellerPortal/Settings/PaymentSettings';
import DeliveryOptions from '../pages/SellerPortal/Settings/DeliveryOptions';
import SupportDetails from '../pages/SellerPortal/Settings/SupportDetails';
import StoreTimings from '../pages/SellerPortal/Settings/StoreTimings';
import NotificationSettings from '../pages/SellerPortal/Settings/NotificationSettings';
import OrderAcceptance from '../pages/SellerPortal/Settings/OrderAcceptance';
import SEOSettings from '../pages/SellerPortal/Settings/SeoSettings';
import TaxSettings from '../pages/SellerPortal/Settings/TaxSettings';
import StorePolicies from '../pages/SellerPortal/Settings/StorePolicies';
import IntegrationsSettings from '../pages/SellerPortal/Settings/IntegrationsSettings';

const SellerRoutes = () => (

    <Routes>
        <Route path="/" element={<LayoutSellerPortal />}>
            <Route path="/dashboard" element={<SessionAuth><Dashboard /></SessionAuth>} />


            <Route path="/products" element={<SessionAuth><ProductPage /></SessionAuth>} />
            <Route path="/products/:productId" element={<SessionAuth><Details /></SessionAuth>} />

            <Route path="/orders" element={<SessionAuth><OrdersPage /></SessionAuth>} />
            <Route path="/reports" element={<SessionAuth><Reports /></SessionAuth>} />

            {/* Main Settings Page */}
            <Route path="/settings" element={<SessionAuth><SettingsPage /></SessionAuth>} />

            {/* Settings Subpages */}
            <Route path="/settings/domain-options" element={<SessionAuth><DomainOptions /></SessionAuth>} />
            <Route path="/settings/manage-store" element={<SessionAuth><ManageStore /></SessionAuth>} />
            <Route path="/settings/user-permissions" element={<SessionAuth><UserPermissions /></SessionAuth>} />
            <Route path="/settings/payment-settings" element={<SessionAuth><PaymentSettings /></SessionAuth>} />
            <Route path="/settings/delivery-options" element={<SessionAuth><DeliveryOptions /></SessionAuth>} />
            <Route path="/settings/support-details" element={<SessionAuth><SupportDetails /></SessionAuth>} />
            <Route path="/settings/store-timings" element={<SessionAuth><StoreTimings /></SessionAuth>} />
            <Route path="/settings/notification-settings" element={<SessionAuth><NotificationSettings /></SessionAuth>} />
            <Route path="/settings/order-acceptance" element={<SessionAuth><OrderAcceptance /></SessionAuth>} />
            <Route path="/settings/seo-settings" element={<SessionAuth><SEOSettings /></SessionAuth>} />
            <Route path="/settings/tax-settings" element={<SessionAuth><TaxSettings /></SessionAuth>} />
            <Route path="/settings/store-policies" element={<SessionAuth><StorePolicies /></SessionAuth>} />
            <Route path="/settings/integrations-settings" element={<SessionAuth><IntegrationsSettings /></SessionAuth>} />
        </Route>
    </Routes>

);

export default SellerRoutes;