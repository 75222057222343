import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  CheckboxGroup,
  Checkbox,
  Input,
  HStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import BackToSettings from '../BackToSettings';

const StoreTimings = () => {
  const [timings, setTimings] = useState([]);
  const toast = useToast();

  const handleSave = () => {
    toast({
      title: 'Store Timings Saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('Store Timings:', timings);
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={6}>
        Store Timings
      </Heading>
      <VStack spacing={4} align="stretch">
        <CheckboxGroup
          onChange={(values) => setTimings(values)}
          value={timings}
        >
          <VStack align="stretch" spacing={4}>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
              (day) => (
                <FormControl key={day}>
                  <FormLabel>{day}</FormLabel>
                  <HStack>
                    <Checkbox value={day}>{day}</Checkbox>
                    <Input type="time" placeholder="Opening Time" />
                    <Input type="time" placeholder="Closing Time" />
                  </HStack>
                </FormControl>
              )
            )}
          </VStack>
        </CheckboxGroup>
        <Button colorScheme="teal" onClick={handleSave}>
          Save Timings
        </Button>
      </VStack>
    </Box>
  );
};

export default StoreTimings;