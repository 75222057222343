import { Box, Switch, Text, HStack } from '@chakra-ui/react';

const PricingSwitch = ({ isYearly, onChange }) => (
  <HStack spacing={3} justify="center" mb={8}>
    <Text fontSize="sm" fontWeight="medium">Monthly</Text>
    <Switch 
      colorScheme="teal" 
      size="lg"
      isChecked={isYearly}
      onChange={(e) => onChange(e.target.checked)}
    />
    <Text fontSize="sm" fontWeight="medium">
      Yearly
      <Text as="span" ml={2} color="teal.500" fontWeight="semibold">
        (Save 15%)
      </Text>
    </Text>
  </HStack>
)

export default PricingSwitch;