import { AiOutlineCloudServer } from 'react-icons/ai';
// import { FaServer } from 'react-icons/fa';

export const plansList = [
  {
    title: 'Starter',
    price: 599,
    icon: AiOutlineCloudServer,
    description: "Perfect to kickstart your online store with essential tools to try, sell and grow.", // Add description similar to Features section
    features: [
      'Upto 25 Products', 
      'Use your own domain', 
      'Basic inventory management',
      'Integrated payment gateway',
      'Email and chat support', 
      'Basic bulk product upload',
      'Single team account'
    ],
    comingSoon: false
  },
  {
    title: 'Professional',
    price: 999,
    icon: AiOutlineCloudServer,
    description: "Elevate your business with advanced features and streamlined operations.",
    features: [
      'Everything in Starter',
      'Priority customer support',
      'Advanced bulk product upload',
      'Multiple team accounts',
      'Free .in/.shop/.store domain for 1 year (with annual plans)',
    ],
    comingSoon: false
  },
  {
    title: 'Enterprise (Coming Soon)',
    price: "XXXX",
    //icon: FaServer,
    icon: AiOutlineCloudServer,
    description: "Scale without limits with premium tools, custom integrations, and 24/7 support.",
    features: [
      'Everything in Professional',
      'Unlimited staff accounts',
      'Dedicated account manager',
      'API access for custom integrations',
      '24/7 premium support'
    ],
    comingSoon: true
  }
];