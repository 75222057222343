import React, { useState, useEffect } from 'react';
import {
  Box, SimpleGrid, Text, Button, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerFooter, Image, HStack, VStack, IconButton, useDisclosure
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FiPlus, FiMinus, FiTrash } from 'react-icons/fi';
import HeaderWithSubnavigation from './Header';
import PostWithLike from './PostWithLike';

// Helper function to save cart to localStorage
const saveCartToLocalStorage = (cart) => {
  localStorage.setItem('cart', JSON.stringify(cart));
};

// Helper function to load cart from localStorage
const loadCartFromLocalStorage = () => {
  const savedCart = localStorage.getItem('cart');
  return savedCart ? JSON.parse(savedCart) : [];
};

const Consumer = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState(loadCartFromLocalStorage());  // Load cart from localStorage on init
  const { isOpen, onOpen, onClose } = useDisclosure();  // Drawer state
  const btnRef = React.useRef();
  const navigate = useNavigate();  // Hook to navigate

  // Fetch product data
  const fetchProductData = async () => {
    try {
      const response = await fetch('http://localhost/api/v1/products/list'); // Adjust API endpoint if necessary
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  // Add product to cart (increase quantity if already added)
  const handleAddToCart = (product) => {
    const existingProductIndex = cart.findIndex(item => item.id === product.id);  // Use unique `id` to compare products
    let updatedCart = [];
    
    if (existingProductIndex !== -1) {
      // If the product is already in the cart, increase the quantity
      updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += 1;
    } else {
      // If the product is not in the cart, add it with quantity 1
      updatedCart = [...cart, { ...product, quantity: 1 }];
    }

    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart);  // Persist cart to localStorage
  };

  // Remove product from cart
  const handleRemoveFromCart = (productId) => {
    const updatedCart = cart.filter(item => item.id !== productId);
    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart);  // Persist cart to localStorage
  };

  // Increase or decrease quantity
  const handleUpdateQuantity = (productId, change) => {
    const updatedCart = cart.map(item =>
      item.id === productId
        ? { ...item, quantity: Math.max(0, item.quantity + change) }
        : item
    );
    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart);  // Persist cart to localStorage
  };

  // Checkout button action
  const handleCheckout = () => {
    navigate('/checkout', { state: { cart } });  // Navigate to checkout and pass cart data
  };

  useEffect(() => {
    fetchProductData();  // Fetch product data when the component mounts
  }, []);

  return (
    <Box>
      <HeaderWithSubnavigation 
        cartItemCount={cart.length} 
        onCartClick={onOpen}  // Open the drawer when the cart icon is clicked
      />

      {/* Drawer for Cart */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={{ base: 'full', md: 'sm' }} // Full width on smaller screens, 500px max width on larger screens
      >
        <DrawerOverlay />
        <DrawerContent maxW={{ md: '500px' }}>
          <DrawerHeader borderBottomWidth="1px">Shopping Cart</DrawerHeader>

          <DrawerBody>
            {cart.length > 0 ? (
              cart.map((item, index) => (
                <HStack key={index} spacing={4} align="flex-start">
                  <Image
                    boxSize="100px"
                    objectFit="cover"
                    src={item.image || 'https://via.placeholder.com/150'}
                    alt={item.name}
                  />
                  <VStack align="start" spacing={1} flex="1">
                    <HStack justify="space-between" width="full">
                      <Text fontWeight="bold">{item.name}</Text>
                      <Text>₹{item.price}</Text>
                    </HStack>
                    <HStack>
                      <IconButton
                        icon={<FiMinus />}
                        onClick={() => handleUpdateQuantity(item.id, -1)}
                        isDisabled={item.quantity === 0}
                        aria-label="Decrease quantity"
                      />
                      <Text>{item.quantity}</Text>
                      <IconButton
                        icon={<FiPlus />}
                        onClick={() => handleUpdateQuantity(item.id, 1)}
                        aria-label="Increase quantity"
                      />
                    </HStack>
                    <IconButton
                      icon={<FiTrash />}
                      onClick={() => handleRemoveFromCart(item.id)}
                      aria-label="Remove from cart"
                      colorScheme="red"
                    />
                  </VStack>
                </HStack>
              ))
            ) : (
              <Text>Your cart is empty</Text>
            )}
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            {cart.length > 0 ? (
              <Button colorScheme="blue" onClick={handleCheckout}>
                Checkout
              </Button>
            ) : (
              <Button colorScheme="blue" isDisabled>
                Checkout
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Display the products using a grid layout */}
      <SimpleGrid
        columns={{ base: 2, sm: 3, md: 4, lg: 5, xl: 6 }} // Increased number of columns for smaller cards
        spacing={{ base: 1, md: 2 }} // Spacing between 1px and 3px
        p={3} // Padding around the grid
      >
        {products.map((product, index) => (
          <PostWithLike
            key={index}
            name={product.name}
            seller={product.seller_name}
            description={product.description}
            price={product.price}
            image={product.image} // Passing the image URL to the PostWithLike component
            onAddToCart={() => handleAddToCart({ ...product, id: product.id || index })}  // Use `id` or fallback to index for unique identification
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Consumer;
