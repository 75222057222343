import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Button,
  useToast,
} from '@chakra-ui/react';
import BackToSettings from '../BackToSettings';

const TaxSettings = () => {
  const [taxSettings, setTaxSettings] = useState({
    gstEnabled: false,
    gstNumber: '',
    vatEnabled: false,
    vatRate: '',
  });
  const toast = useToast();

  const handleSave = () => {
    toast({
      title: 'Tax Settings Saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('Tax Settings:', taxSettings);
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={6}>Tax Settings</Heading>
      <VStack spacing={4} align="stretch">
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable GST</FormLabel>
          <Switch
            isChecked={taxSettings.gstEnabled}
            onChange={(e) =>
              setTaxSettings({ ...taxSettings, gstEnabled: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        {taxSettings.gstEnabled && (
          <FormControl>
            <FormLabel>GST Number</FormLabel>
            <Input
              placeholder="Enter your GST number"
              value={taxSettings.gstNumber}
              onChange={(e) =>
                setTaxSettings({ ...taxSettings, gstNumber: e.target.value })
              }
            />
          </FormControl>
        )}
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable VAT</FormLabel>
          <Switch
            isChecked={taxSettings.vatEnabled}
            onChange={(e) =>
              setTaxSettings({ ...taxSettings, vatEnabled: e.target.checked })
            }
            colorScheme="teal"
          />
        </FormControl>
        {taxSettings.vatEnabled && (
          <FormControl>
            <FormLabel>VAT Rate (%)</FormLabel>
            <Input
              placeholder="Enter VAT rate"
              value={taxSettings.vatRate}
              onChange={(e) =>
                setTaxSettings({ ...taxSettings, vatRate: e.target.value })
              }
            />
          </FormControl>
        )}
        <Button colorScheme="teal" onClick={handleSave}>
          Save Tax Settings
        </Button>
      </VStack>
    </Box>
  );
};

export default TaxSettings;