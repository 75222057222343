import React, { useEffect, useState } from "react";
import Session from "supertokens-auth-react/recipe/session";
import { Button, Flex } from "@chakra-ui/react";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AuthButton = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    // Check session status on component mount
    useEffect(() => {
        const checkSession = async () => {
            const sessionExists = await Session.doesSessionExist();
            setIsLoggedIn(sessionExists);
        };
        checkSession();
    }, []);

    // Logout handler
    const handleLogout = async () => {
        await Session.signOut();
        setIsLoggedIn(false);
        navigate("/login"); // Redirect to login page after logout
    };

    // Conditional rendering based on session status
    return (
        <>
            {isLoggedIn ? (
                <Flex justify="flex-end" gap={4} p={4}>

                    <Button
                        colorScheme="teal"
                        variant="outline"
                        onClick={() => navigate("/dashboard")}
                    >
                        Dashboard
                    </Button>
                    <Button
                        leftIcon={<FaSignOutAlt />}
                        colorScheme="red"
                        variant="solid"
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                </Flex>
            ) : (

                <Flex justify="flex-end" gap={4} p={4}>
                    {/* <Button
                        colorScheme="teal"
                        variant="ghost"
                        onClick={() => navigate("/login")}
                    >
                        Login
                    </Button>
                     <Button
                        colorScheme="red"
                        variant="solid"
                        
                        onClick={() => navigate(`/onboarding`)}
                        
                    >
                        Get Started
                    </Button> */}
                </Flex>
            )}
        </>
    );
};

export default AuthButton;