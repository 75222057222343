import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Switch,
  Button,
  useToast,
} from '@chakra-ui/react';
import BackToSettings from '../BackToSettings';

const OrderAcceptance = () => {
  const [orderAcceptance, setOrderAcceptance] = useState({
    autoAccept: false,
    manualReview: true,
  });
  const toast = useToast();

  const handleSave = () => {
    toast({
      title: 'Order Acceptance Settings Saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('Order Acceptance Settings:', orderAcceptance);
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={6}>
        Order Acceptance
      </Heading>
      <VStack spacing={4} align="stretch">
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable Automatic Order Acceptance</FormLabel>
          <Switch
            isChecked={orderAcceptance.autoAccept}
            onChange={(e) =>
              setOrderAcceptance({
                ...orderAcceptance,
                autoAccept: e.target.checked,
                manualReview: !e.target.checked,
              })
            }
            colorScheme="teal"
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Require Manual Review for Orders</FormLabel>
          <Switch
            isChecked={orderAcceptance.manualReview}
            onChange={(e) =>
              setOrderAcceptance({
                ...orderAcceptance,
                manualReview: e.target.checked,
                autoAccept: !e.target.checked,
              })
            }
            colorScheme="teal"
          />
        </FormControl>
        <Button colorScheme="teal" onClick={handleSave}>
          Save Order Acceptance Settings
        </Button>
      </VStack>
    </Box>
  );
};

export default OrderAcceptance;