import { Button } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const BackToSettings = () => {
  const navigate = useNavigate();
  return (
    <Button
      leftIcon={<ChevronLeftIcon />}
      variant="ghost"
      color="gray.500"
      onClick={() => navigate('/settings')}
      mb={4}
    >
      Back to Settings
    </Button>
  );
};

export default BackToSettings;