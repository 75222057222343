// components/EmailForm.js
import React, { useState } from "react";
import { SimpleGrid, GridItem, VisuallyHidden, Input, Button, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";

const MotionVStack = motion(VStack);

const EmailForm = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/onboarding?email=${encodeURIComponent(email)}`);
  };

  return (
    <MotionVStack
      spacing={4}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4, duration: 0.5 }}
    >
    <SimpleGrid
      as="form"
      w={{ base: "full", md: 7 / 12 }}
      columns={{ base: 1, lg: 6 }}
      spacing={3}
      pt={10}
      mx="auto"
      mb={8}
      
    >
      <GridItem as="label" colSpan={{ base: "auto", lg: 4 }}>
        <VisuallyHidden>Your Email</VisuallyHidden>
        
        <Input
          type="email"
          placeholder="Enter your email to get started"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          pr="8rem"
          size="lg"
          fontSize="md"
          bg="white"
          borderWidth={2}
          required
          _hover={{ borderColor: "teal.500" }}
          _focus={{ 
            borderColor: "teal.500", 
            boxShadow: "0 0 0 1px var(--chakra-colors-teal-500)"
          }}
        />
      </GridItem>
      <Button
        as={GridItem}
        w="full"
        variant="solid"
        colSpan={{ base: "auto", lg: 2 }}
        size="lg"
        type="submit"
        cursor="pointer"
        rightIcon={<FiArrowRight />}
        onClick={handleSubmit}
        colorScheme="red"
      >
        Get Started
      </Button>
    </SimpleGrid>
    </MotionVStack>
  );
};


export default EmailForm;