import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Textarea,
  Select
} from '@chakra-ui/react';
import BackToSettings from '../BackToSettings';

const SEOSettings = () => {
  const [seoSettings, setSeoSettings] = useState({
    title: '',
    metaDescription: '',
    socialImage: '',
  });
  const toast = useToast();

  const handleSave = () => {
    toast({
      title: 'SEO Settings Saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('SEO Settings:', seoSettings);
  };

  return (
    <Box p={6}>
      <BackToSettings />
      <Heading size="lg" mb={6}>
        SEO Settings
      </Heading>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Homepage Title</FormLabel>
          <Input
            placeholder="Enter homepage title"
            value={seoSettings.title}
            onChange={(e) => setSeoSettings({ ...seoSettings, title: e.target.value })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Meta Description</FormLabel>
          <Textarea
            placeholder="Enter meta description"
            value={seoSettings.metaDescription}
            onChange={(e) =>
              setSeoSettings({ ...seoSettings, metaDescription: e.target.value })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Social Sharing Image URL</FormLabel>
          <Input
            placeholder="Enter image URL"
            value={seoSettings.socialImage}
            onChange={(e) =>
              setSeoSettings({ ...seoSettings, socialImage: e.target.value })
            }
          />
        </FormControl>
        <Button colorScheme="teal" onClick={handleSave}>
          Save SEO Settings
        </Button>
      </VStack>
      <VStack spacing={4} align="stretch">
    <FormControl>
        <FormLabel>Canonical URL</FormLabel>
        <Input placeholder="Enter canonical URL" />
    </FormControl>
    <FormControl>
        <FormLabel>Schema Markup</FormLabel>
        <Textarea placeholder="Enter schema markup as JSON-LD" />
    </FormControl>
    <FormControl>
        <FormLabel>Robots Meta Tag</FormLabel>
        <Select placeholder="Select robots meta tag">
            <option value="index,follow">Index, Follow</option>
            <option value="noindex,follow">No Index, Follow</option>
            <option value="index,nofollow">Index, No Follow</option>
            <option value="noindex,nofollow">No Index, No Follow</option>
        </Select>
    </FormControl>
</VStack>
    </Box>
  );
};

export default SEOSettings;