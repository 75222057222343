import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';

const PaymentSettings = () => {
  const [paymentMethods, setPaymentMethods] = useState({
    razorpay: true,
    stripe: false,
    paypal: false,
    manual: false,
  });
  const [manualPaymentInstructions, setManualPaymentInstructions] = useState('');
  const toast = useToast();

  const handleSave = () => {
    toast({
      title: 'Payment Settings Saved',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    console.log('Payment methods:', paymentMethods);
    console.log('Manual payment instructions:', manualPaymentInstructions);
  };

  return (
    <Box p={6}>
      <Heading size="lg" mb={4}>
        Payment Settings
      </Heading>
      <VStack spacing={4} align="stretch">
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable Razorpay</FormLabel>
          <Switch
            isChecked={paymentMethods.razorpay}
            onChange={(e) => setPaymentMethods({ ...paymentMethods, razorpay: e.target.checked })}
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable Stripe</FormLabel>
          <Switch
            isChecked={paymentMethods.stripe}
            onChange={(e) => setPaymentMethods({ ...paymentMethods, stripe: e.target.checked })}
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable PayPal</FormLabel>
          <Switch
            isChecked={paymentMethods.paypal}
            onChange={(e) => setPaymentMethods({ ...paymentMethods, paypal: e.target.checked })}
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel flex="1">Enable Manual Payments</FormLabel>
          <Switch
            isChecked={paymentMethods.manual}
            onChange={(e) => setPaymentMethods({ ...paymentMethods, manual: e.target.checked })}
          />
        </FormControl>
        {paymentMethods.manual && (
          <FormControl>
            <FormLabel>Manual Payment Instructions</FormLabel>
            <Input
              placeholder="Enter instructions for manual payment"
              value={manualPaymentInstructions}
              onChange={(e) => setManualPaymentInstructions(e.target.value)}
            />
          </FormControl>
        )}
        <Button colorScheme="teal" onClick={handleSave}>
          Save Settings
        </Button>
      </VStack>
    </Box>
  );
};

export default PaymentSettings;