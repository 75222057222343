import { Box, Button, Text, VStack, Heading, StackDivider, HStack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BuyDomain from './BuyDomain';
import ConnectDomain from './ConnectDomain';
import axios from 'axios';

const DomainOptions = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [domains, setDomains] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    // Check if accessed from Getting Started Guide
    const isFromGettingStarted = location.state?.isFromGettingStarted || false;

    useEffect(() => {
        // Fetch existing domains for the shop
        const fetchDomains = async () => {
            try {
                const response = await axios.get('/api/domains'); // Replace with your API endpoint
                setDomains(response.data);
            } catch (error) {
                console.error('Error fetching domains:', error);
            }
        };

        fetchDomains();
    }, []);

    return (
        <Box position="relative" p={6}>
            <VStack align="stretch" spacing={4} divider={<StackDivider borderColor="gray.200" />}>
                <Heading as="h2" size="lg">Domains</Heading>
                <Text fontSize="md" color="gray.600">
                    Create a professional web presence by securing a domain your customers can easily remember.
                    Choose to purchase a new domain or connect one you've already bought from providers like Google Domains or GoDaddy.
                </Text>

                {/* Action Buttons */}
                {!selectedOption && (
                    <HStack spacing={4} align="start">
                        <Button colorScheme="blue" onClick={() => setSelectedOption("buy")}>
                            Buy a New Domain
                        </Button>
                        <Button colorScheme="gray" onClick={() => setSelectedOption("connect")}>
                            Connect Existing Domain
                        </Button>
                    </HStack>
                )}

                {/* Display Buy or Connect Domain Components */}
                {selectedOption === "buy" && <BuyDomain onGoBack={() => setSelectedOption(null)} />}
                {selectedOption === "connect" && <ConnectDomain onGoBack={() => setSelectedOption(null)} />}

                {/* Existing Domains */}
                {domains.length > 0 && (
                    <Box mt={8}>
                        <Heading as="h3" size="md" mb={4}>Connected Domains</Heading>
                        <VStack align="stretch" spacing={4}>
                            {domains.map((domain, index) => (
                                <Box
                                    key={index}
                                    p={4}
                                    bg="gray.50"
                                    borderWidth="1px"
                                    borderColor="gray.200"
                                    rounded="md"
                                >
                                    <Text fontWeight="bold">{domain.name}</Text>
                                    <Text fontSize="sm" color="gray.600">Status: {domain.status}</Text>
                                </Box>
                            ))}
                        </VStack>
                    </Box>
                )}

                {/* Back Button */}
                {isFromGettingStarted && (
                    <Button size="sm" variant="link" colorScheme="blue" mt={4} onClick={() => navigate("/dashboard")}>
                        Back to Getting Started Guide
                    </Button>
                )}
            </VStack>
        </Box>
    );
};

export default DomainOptions;