import TemplateSelection from '../pages/SellerPortal/TemplateSelection';

// const navigate = useNavigate();
// steps.js
const steps = [
    {
        title: "What is your shop name?",
        description: "Choose a name for your shop, something you are already using or something simple and easy to remember.",
        component: "shopName",
        //TODO: Add a link to learn more about choosing a shop name
        //TODO : Add a tool to create name based on user input
        //TODO: Add a tool to check if the name is available
        // TODO: Option to generating a name for shop based on user input, and checking if it is available can be combined into one
    },
    {
        title: "Add your first product",
        description: "Write a description, add photos, and set pricing for the products you plan to sell.",
        learnMore: true,
        actions: [
            { label: "Add product", primary: true, actionType: "ADD_PRODUCT"},
            { label: "Import products", primary: false, actionType: "IMPORT_PRODUCT"},
            { label: "Create sample product", primary: false, actionType: "ADD_SAMPLE_PRODUCT"},
        ]
    },
    {
        title: "Select look and feel of your shop",
        description: "Choose from professionally designed themes to create your store’s look and feel.",
        component: <TemplateSelection />,
    },
    {
        title: "Add a custom domain",
        description: "Make your store more memorable with a custom domain.",
        actions: [
            {
                label: "Setup your domain",
                primary: true,
                actionType: "SETUP_DOMAIN"
            }
        ],
    },
    {
        title: "Set your shipping rates",
        description: "Define the shipping methods and rates for delivering your products.",
    },
    {
        title: "Set up a payment provider",
        description: "Enable customers to pay using their preferred payment method.",
    },
    {
        title: "Place a test order",
        description: "Ensure everything works by placing a test order.",
    },
];

export default steps;