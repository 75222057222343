import { Box, Container, Divider, Text, VStack } from "@chakra-ui/react";
import { FooterLinks } from "./FooterLinks";
import { SocialLinks } from "./SocialLinks";
// import { Newsletter } from "./Newsletter.js";
// import { Logo } from "../../../assets/Logo";
import logo from '../../../assets/logo.png';

const Footer = () => (
  <Box as="footer"  py={16}>
    <Container maxW="7xl">
      <VStack spacing={10} align="stretch">
        <Box>
          {/* <Logo /> */}
          <Text mt={4} color="gray.500" maxW="md">
            Empowering businesses to grow online with our simple and powerful e-commerce platform.
          </Text>
        </Box>

        <FooterLinks />
        
        <Divider />
        
        {/* <Box>
          <Newsletter />
        </Box> */}
        
        <Divider />
        
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <Text color="gray.500" fontSize="sm">
            © {new Date().getFullYear()} MiniTaka. All rights reserved.
          </Text>
          <SocialLinks />
        </Box>
      </VStack>
    </Container>
  </Box>
);

export default Footer;